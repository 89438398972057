<div class="navbar-container white-background" [ngClass]="{'position' : isTopBarFixed }">
  <nav class="custom-navbar navbar 'white-background">
    <div class="container-box">
      <div>
        <a [routerLink]="['/']" aria-label="Accueil - logo du centre">
          <img class="logo-anybuddy" [src]="centerLogo" alt="logo du centre" width="200" height="72">
        </a>
      </div>
      <div class="test" *ngIf="!isMobileResolution">
        <ng-container *ngTemplateOutlet="leftNavBar"></ng-container>
      </div>
    </div>
    <div class="container-box left">
      <div *ngIf="!isMobileResolution">
        <ng-container *ngTemplateOutlet="rightNavBar"></ng-container>
      </div>
      <div class="me-4 ms-2" *ngIf="!isMobileResolution">
        <app-lang-choice [idSelector1]="idSelector1Langage" [idSelector2]="idSelector2Langage"
          [yellow]="false"></app-lang-choice>
      </div>
      <div class="ms-auto" *ngIf="isMobileResolution">
        <button class="light" (click)="sidebarShow = !sidebarShow" aria-label="Afficher/masquer la barre latérale">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
          </svg>
        </button>
      </div>
    </div>
  </nav>
</div>


<div class="bg-nav-slider" [class.bg-nav-slider-in]="sidebarShow"></div>

<div class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow">
  <div class="sidebar-close" (click)="sidebarShow = !sidebarShow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
    </svg>
  </div>
  <div class="sidebar-content">
    <div *ngIf="isMobileResolution" class="menuMobile mt-5">
      <div class="fs-5 my-2" (click)="toggleMenu()">
        <span class="clickable" [routerLink]="['/']">{{ 'menuComponent.home' | translate }}</span>
      </div>
      <div *ngIf="this.isLoggedIn()">
        <div class="fs-5 my-2" (click)="toggleMenu()">
          <span class="clickable" [routerLink]="['/account']">{{ 'my_bookings.my_reservations' | translate }}</span>
        </div>
        <div class="fs-5 my-2" (click)="toggleMenu()">
          <span class="clickable" [routerLink]="['/account/profile']">{{ 'myProfile.profile' | translate }}</span>
        </div>
        <div class="fs-5 my-2" (click)="toggleMenu()">
          <span class="clickable" [routerLink]="['/account/wallets']">{{ 'wallets.my_wallets' | translate }}</span>
        </div>
        <div class="fs-5 my-2">
          <span class="clickable" (click)="toggleMenu()" [routerLink]="['/logout']">{{ 'menuComponent.logout' | translate}}</span>
        </div>
      </div>
      <div class="fs-5 my-2" *ngIf="!this.isLoggedIn()" (click)="toggleMenu()">
        <span class="clickable" [routerLink]="['/login']">{{ 'menuComponent.login' | translate}}</span>
      </div>

      <ng-container *ngTemplateOutlet="leftNavBar"></ng-container>
    </div>

    <div *ngIf="isMobileResolution" class="lang mt-2" (click)="openLangageModal(content)">
      <div class="d-flex" style="text-align: left">
        <div class="my-auto">
          <span class="font-weight-bold" style="font-size: 1.3rem">
            {{('languagesCountriesLang.' + languageChoosed.languageCode) | translate}} ({{
            languageChoosed.trueLanguageCode | uppercase }})
          </span>
        </div>
        <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
        </svg>
      </div>
    </div>

  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close p-0" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <app-lang-choice [footer]="true"></app-lang-choice>
  </div>
</ng-template>

<ng-template #rightNavBar>
  <div class="row" *ngIf="!isLoginPages()">
    <app-button-account [isMarket]="false"></app-button-account>
  </div>
</ng-template>

<ng-template #leftNavBar>
  <ng-content select="[app-header-left]"></ng-content>
</ng-template>
