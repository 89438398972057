import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    private router: Router,
    private firebaseAuth: AuthFirebaseService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (isPlatformBrowser(this.platform) && this.firebaseAuth.getIsLoggedIn) {
      // logged in so return true
      return true;
    }
    /*
    const url = state.url.split('?');
    if (url.length> 1 && url[1].includes('redirectUrl')) {
      const redirectUrl = url[1].split('=')[1];
      this.router.navigate(['/login'], { queryParams: { redirectUrl: redirectUrl}});

    }

     */
    this.router.navigate(['/login'], { queryParams: { redirectUrl: state.url}});
    return false;
  }
}
