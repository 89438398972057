
declare interface TownData {
  name: string;
  sports: string;
  link: string;
  background: string;
}


export const TOWN_LIST: TownData[] = [
  { name: 'Marseille', link: 'reservation-sport/marseille-13000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1671100995/assets/city/ville-marseille.jpg' },
  { name: 'Bordeaux', link: 'reservation-sport/bordeaux-33000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186313/assets/city/ville-grenoble.jpg' },
  { name: 'Lyon', link: 'reservation-sport/lyon-69000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-lyon.jpg' },
  { name: 'Lille', link: 'reservation-sport/lille-59000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186313/assets/city/ville-lille.jpg' },
  { name: 'Grenoble', link: 'reservation-sport/grenoble-38000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186313/assets/city/ville-grenoble.jpg' },
  { name: 'Nice', link: 'reservation-sport/nice-06000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-nice.jpg' },
  { name: 'Nancy', link: 'reservation-sport/nancy-54000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-nancy.jpg' },
  { name: 'Montpellier', link: 'reservation-sport/montpellier-34000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186313/assets/city/ville-montpellier.jpg' },
  { name: 'Paris', link: 'reservation-sport/paris-75000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-paris.jpg' },
  { name: 'Rennes', link: 'reservation-sport/rennes-35000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-rennes.jpg' },
  { name: 'Strasbourg', link: 'reservation-sport/strasbourg-67000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-strasbourg.jpg' },
  { name: 'Toulouse', link: 'reservation-sport/toulouse-31000', sports: 'Badminton, Tennis, Padel, Squash', background: 'https://res.cloudinary.com/anybuddy/image/upload/c_scale,h_200/f_auto,q_auto:good/v1586186314/assets/city/ville-toulouse.jpg' },
];
