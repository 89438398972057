import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as cryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { CustomValidators } from '../validators/custom-validators';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isMobileResolution = false;
  email: UntypedFormControl;
  magicLink: UntypedFormControl;
  password: UntypedFormControl;
  errorMessage: any;
  error = false;
  code;
  state;
  persistConnexion = false;
  country = 'fr';
  language = '';
  errorAuthMsg = '';
  errorAuthMail = '';
  userMail = '';

  loginMode = true;

  isConnecting = false;
  isSubscribing = false;
  isSendingMagicLink = false;

  isAuthentifiying = false;

  @ViewChild('downlodApp') downlodApp: TemplateRef<any>;
  @ViewChild('errorAuth') errorAuth: TemplateRef<any>;
  @ViewChild('linkMailSent') linkMailSent: TemplateRef<any>;
  @ViewChild('checkMail') checkMail: TemplateRef<any>;
  @ViewChild('magicPopUp') magicPopUp: TemplateRef<any>;

  constructor(
    private authFirebaseService: AuthFirebaseService,
    public authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private userPreferenceService: UserPreferenceService,
    private http: HttpClient,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platform
  ) {
    this.route.queryParams.subscribe((params) => {
      this.code = params.code;
      this.state = params.state;
    });
    this.getIsAuthentifiying();
    if (this.authFirebaseService.isLoggedIn) {
      this.navigateEndLogin();
    }
  }

  ngOnInit(): void {
    if (this.isAuthentifiying) {
      this.getResultSignUp();
    }
    this.route.data.subscribe((data) => {
      if (data.name == 'login') {
        this.loginMode = true;
      } else {
        this.loginMode = false;
      }
    });
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }
    this.userPreferenceService.getlocalChangeCountry().subscribe((value) => {
      this.country = value;
      if (this.country !== 'fr') {
        this.language = this.country;
      }
    });
    if (this.userPreferenceService.getCountry() === 'es') {
      this.router.navigate(['/es/login-decathlon']);
    }

    if (this.code && this.state) {
      this.getDecathlonAccessToken(this.code);
    }
    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]);
    this.magicLink = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]);

    if (this.loginMode) {
      this.password = new UntypedFormControl('', [Validators.required]);
    } else {
      this.password = new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          CustomValidators.patternValidator(/[ !@#$%^&*()_+=\-{};':"|,.<>/?]/, {
            hasSpecialCharacters: true
          }),
          Validators.minLength(8)
        ])
      );
    }
  }

  getIsAuthentifiying(): void {
    const isAuthentifiying = localStorage.getItem('isAuthentifiying');
    if (isAuthentifiying !== null && isAuthentifiying === 'true') {
      this.isAuthentifiying = true;
    }
  }

  signUp() {
    this.error = false;
    this.errorMessage = null;
    this.userMail = this.email.value;
    if (this.isMailValid() && this.isPasswordValid()) {
      this.isSubscribing = true;
      this.authFirebaseService
        ._signUp(this.email.value, this.password.value)
        .then(
          () => {
            this.modalService.open(this.checkMail);
            this.isSubscribing = false;
          },
          (error) => {
            this.error = true;
            this.errorMessage = error;
            this.isSubscribing = false;
          }
        );
    }
  }

  signIn() {
    this.error = false;
    this.errorMessage = null;
    if (this.isMailValid() && this.isPasswordValid()) {
      this.isConnecting = true;
      this.authFirebaseService
        ._signIn(this.email.value, this.password.value)
        .then(
          () => {
            this.isConnecting = false;
          },
          (error) => {
            this.error = true;
            this.errorMessage = error;
            this.isConnecting = false;
          }
        );
    }
  }

  isMailValid(): boolean {
    this.email.markAsTouched();
    return this.email.valid;
  }

  isPasswordValid(): boolean {
    this.password.markAsTouched();
    return this.password.valid;
  }

  signUpGoogle(): void {
    this.authFirebaseService
      ._googleAuth()
      .then((value) => {
        this.router.navigate(['/dashboard']);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.error('google', errorMessage);
      });
  }

  signUpFb(): void {
    this.authFirebaseService
      ._facebookAuth()
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.error('signUpFb', errorMessage);
      });
  }

  signUpApple(): void {
    this.authFirebaseService
      ._appleAuth()
      .then((value) => {
        console.log(value);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.error('signUpApple', errorMessage);
      });
  }

  signUpMailLink(): void {
    this.errorAuthMsg = '';
    this.errorAuthMail = '';
    this.userMail = this.magicLink.value;
    this.magicLink.markAsTouched();
    if (this.magicLink.valid) {
      this.isSendingMagicLink = true;
      this.authFirebaseService
        ._signUpWithMail(this.userMail)
        .then(() => {
          this.modalService.dismissAll();
          window.localStorage.setItem('emailForSignIn', this.userMail);
          this.modalService.open(this.linkMailSent);
          this.isSendingMagicLink = false;
        })
        .catch((error) => {
          this.errorAuthMsg = error.message;
          this.isSendingMagicLink = false;
        });
    }
  }

  downloadApp(): void {
    this.modalService.open(this.downlodApp, {
      centered: true,
      windowClass: 'customModalDownloadApp'
    });
  }

  public onCloseModal(modal: NgbActiveModal): void {
    modal.close();
  }

  getResultSignUp(): void {
    this.authFirebaseService
      ._getRedirectResult()
      .then((value) => {
        this.isAuthentifiyingEnd();
        this.authFirebaseService.sendLoginManager = true;
        console.log(value);
      })
      .catch((error) => {
        this.isAuthentifiyingEnd();
        this.errorAuthMsg = error.message;
        if (error.customData) {
          this.errorAuthMail = error.customData.email;
        }
        this.modalService.open(this.errorAuth);
      });
  }

  isAuthentifiyingEnd(): void {
    localStorage.setItem('isAuthentifiying', 'false');
    this.isAuthentifiying = false;
  }

  getDecathlonAccessToken(code: string): void {
    if (isPlatformBrowser(this.platform)) {
      const codeVerifier = sessionStorage.getItem('codeVerifier');
      sessionStorage.removeItem('codeVerifier');
      const payload = new HttpParams()
        .append('grant_type', 'authorization_code')
        .append('code', code)
        .append('code_verifier', codeVerifier)
        .append('redirect_uri', `${environment.host}/login`)
        .append('client_id', environment.clientId)
        .append('client_secret', environment.clientSecret);
      this.http
        .post(environment.accessToken, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .subscribe({
          next: (response) => {
            {
              const token = 'access_token';
              this.authService
                .authWithProvider('decathlon', response[token])
                .subscribe({
                  next: (res) => {
                    this.authFirebaseService
                      .SignInWithCustomToken(res.body.accessToken)
                      .then(() => {
                        this.authFirebaseService.sendLoginManager = true;
                        localStorage.setItem('sendLoginManager', 'true');
                        this.navigateEndLogin();
                      })
                      .catch((error) => {
                        console.error(error);
                        this.error = true;
                        this.errorAuthMsg = error.message;
                        this.modalService.open(this.errorAuth);
                      });
                  },
                  error: (error) => {
                    console.error(error);
                    this.error = true;
                    if (error.status === 401) {
                      this.errorMessage =
                        this.translate.instant('login.error_login');
                    } else {
                      this.errorMessage = error.message;
                    }
                    this.errorAuthMsg = error.message;
                    this.modalService.open(this.errorAuth);
                  }
                });
            }
          },
          error: (err) => {
            this.error = true;
            this.errorMessage = err.error.error_description;
          },
          complete: () => {
            this.navigateEndLogin();
          }
        });
    }
  }

  private strRandom(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  goToDecathlonLoginPage(): void {
    const state = this.strRandom(43);
    const codeVerifier = this.strRandom(128);
    const codeVerifierHash = cryptoJS
      .SHA256(codeVerifier)
      .toString(cryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.clientId,
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + environment.host + '/login'
    ];
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.setItem('codeVerifier', codeVerifier);
      window.location.href = environment.authorize + '?' + params.join('&');
    }
  }

  navigateEndLogin(): void {
    this.route.queryParams.subscribe((params) => {
      const redirectUrl = params['redirectUrl'];
      if (redirectUrl) {
        this.router.navigate(redirectUrl);
      } else {
        this.router.navigate(['/account']);
      }
    });
  }

  openMagicLinkPopUp() {
    this.modalService.open(this.magicPopUp);
  }
}
