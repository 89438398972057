<div class="container-main">
  <div class="row w-100 h-100 m-0 content">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 mx-auto max-width">
      <div class="login-card">
        <div class="title-card">
          <h1 class="title">
            {{ 'white_label_login.login_title' | translate }}
          </h1>
        </div>
        <ng-container *ngTemplateOutlet="loginForm"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loginForm>
  <div class="login-form">
    <div class="input-elements col-12 col-md-10">
      <input
        class="custom-input"
        placeholder="{{ 'white_label_login.email' | translate }}"
        type="email"
        [formControl]="email"
      />
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.email, 'email') }}
          </p>
        </div>
      </div>
    </div>

    <div class="input-elements col-12 col-md-10">
      <input
        class="custom-input"
        placeholder="{{ 'white_label_login.password' | translate }}"
        [formControl]="password"
        type="password"
        required
      />
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.password, 'password') }}
          </p>
        </div>
      </div>
    </div>

    <div class="row connexion-option">
      <div class="col-12 col-md-8 mx-auto mt-2 mb-3">
        <button
          class="email-link-btn"
          (click)="signIn()"
          [disabled]="isConnecting"
          [ngClass]="{ 'btn-disabled': isConnecting }"
        >
          {{ 'white_label_login.connect' | translate }}
        </button>
      </div>
    </div>
    <div class="col-12">
      <div class="container-forget-pwd mt-2">
        <a
          class="mx-auto"
          [routerLink]="['/retrieve']"
          routerLinkActive="router-link-active"
        >
          <span class="forget-pwd">{{
            'white_label_login.retrieve_password' | translate
          }}</span>
        </a>
      </div>

      <div class="container-forget-pwd mb-3 mt-1">
        <a
          class="mx-auto"
          [routerLink]="['/reset']"
          routerLinkActive="router-link-active"
        >
          <span class="forget-pwd">{{
            'white_label_login.reset_password' | translate
            }}</span>
        </a>
      </div>


      <div class="col-12">
        <div class="container-forget-pwd d-flex mt-3">
          <span
            >{{ 'white_label_login.not_member' | translate }}<br />
            <a routerLink="/register" class="register_not_member">{{
              'white_label_login.create_account' | translate
            }}</a>
          </span>
        </div>
      </div>

      <div class="col-12 connexion-error">
        <span class="">{{
          'white_label_login.login_error_info'
            | translate
        }} </span>
        <div class="d-flex m-0" *ngFor="let center of centers">
          {{
            'white_label_login.conciergerie' | translate : { center: center.name }
          }} <a [href]="'tel:' + center.phoneNumber">{{center.phoneNumber}}</a>
        </div>
      </div>


    </div>
  </div>
</ng-template>

<ng-template #NotMemberInstruction let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button
      type="button"
      style="background: white; border: aliceblue"
      aria-label="Close"
      (click)="onCloseModal(modal)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"
        />
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <div class="join-us">
      <div class="head">
        <h3>{{ 'white_label_login.join_us' | translate }}</h3>
      </div>
      <div class="center">
        <p>
          {{
            'white_label_login.join_us_center'
              | translate : { center: center.name }
          }}
        </p>
      </div>
      <div class="anybuddy">
        <p>
          {{ 'white_label_login.join_us_anybuddy' | translate }}
          <a href="http://anybuddyapp.com/">{{
            'white_label_login.anybuddy' | translate
          }}</a>
        </p>
      </div>
    </div>
  </div>
</ng-template>
