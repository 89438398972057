import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserProfile } from '../../models/user-profile';
import { BookingService } from '../../service/booking.service';
import { BrowserStorageService } from '../../service/storage.service';
import { UserService } from '../../service/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewReservation } from '../../models/new-reservation';
import { TranslateService } from '@ngx-translate/core';
import { AuthFirebaseService } from '../../service/auth-firebase.service';
import { ReservationsService } from '../../service/reservations.service';
import { TagManagerService } from '../../service/tag-manager.service';
import { Card } from '../card.interface';
import { CardUpdateService } from '../../service/card-update.service';
import { StripeService } from '../../../shared/services/stripe.service';
import { Stripe, StripeElements } from '@stripe/stripe-js';
/* eslint-disable */
@Component({
  selector: 'app-stripe-component',
  templateUrl: './stripe-component.component.html',
  styleUrls: ['./stripe-component.component.scss']
})
export class StripeComponentComponent implements OnInit, OnDestroy {
  stripe: Stripe;
  elements: StripeElements;
  card: any;
  hasPaid: boolean = false;
  isPaidSuccess: boolean;
  isDisabled: boolean = false;
  isUsedPromo: boolean = false;
  isLoad: boolean = false;
  errorPayment: string;
  clientSecret: string;
  slotId: string;
  cardElement: any;
  errorName: boolean = false;
  errorMail: boolean = false;
  error: boolean = false;
  errorState: boolean = false;
  acceptConditions: boolean = false;
  errorConditions: boolean = false;
  isShowCard: boolean = true;
  savedcheck = false;
  selectedCard: Card;
  newcardselected = false;
  message: string;
  modalRef: any;
  modalRef2: any;
  userProfile: UserProfile = new UserProfile('', '', null);
  paymentType: string = 'all';
  isBrowser: boolean = false;
  loader: boolean = false;
  svgContainer: any;
  animItem: any;
  reservationId: string;
  eventId: string;
  inPayment = false;
  matchId : string = "";

  selectNewCard: boolean = false;
  cardToDelete: Card;
  blockBtnPayment: boolean = false;

  @Input() newReservation: NewReservation;
  @Input() center;
  @Input() service;
  @Input() cartId;
  @Input() date;
  @Input() duration;
  @Input() isWhiteLabel = false;
  @Input() cards: Card[];
  public lottieConfig: Object;

public hasMatchId : boolean = true;
public matchIdStarted : boolean = false;

  constructor(
    private modalService: NgbModal,
    private bookingService: BookingService,
    private sessionStorageService: BrowserStorageService,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authFirebaseService: AuthFirebaseService,
    private reservationsService: ReservationsService,
    private tagManager: TagManagerService,
    private cardUpdateService: CardUpdateService,
    private stripeService: StripeService
  ) {
    this.userService.getMe().subscribe((user) => {
      this.userProfile.firstName = user.firstName;
      this.userProfile.lastName = user.lastName;
    });
    this.userProfile.email = this.authFirebaseService.getUser().email;
  }

  loaded = false;

  async ngOnInit() {
    this.stripe = await this.stripeService.getStripe();
    this.selectDefaultCard();
    this.newCardSelection();
  }

  ngOnDestroy(): void {
    if(this.selectNewCard) {
      this.clearResa();
    }
  }

  deleteResa(resaId: string): void {
    this.reservationsService.cancelReservation(resaId).subscribe(
      (res) => {},
      (error) => {}
    );
  }

  initStripeCreditCard(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Create an instance of Elements.
      const clientSecret = this.clientSecret;
      console.log(clientSecret)
      console.log(this.clientSecret)
      if (clientSecret) {
        this.elements = this.stripe.elements({ clientSecret });
        this.cardElement = this.elements.create('payment');
        this.cardElement.mount('#payment-element');
      } else {
        this.error = true;
        this.isPaidSuccess = false;
        this.errorPayment =
          "Erreur lors de l'initialisation du module de paiement";
      }
    }
  }

  handleFormSubmission(withCardSelected = false) {
    this.blockBtnPayment = true;
    if (
      !this.userProfile.firstName ||
      this.userProfile.firstName == '' ||
      !this.userProfile.lastName ||
      this.userProfile.lastName == ''
    ) {
      this.errorPayment = this.translate.instant('checkout.error_name');
      this.isPaidSuccess = false;
      this.errorName = true;
      this.error = true;
      this.blockBtnPayment = false;
    } else if (
      this.userProfile.email == null ||
      this.userProfile.email == '' ||
      !this.userProfile.email.match('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
    ) {
      this.errorPayment = this.translate.instant('checkout.error_mail');
      this.isPaidSuccess = false;
      this.errorMail = true;
      this.error = true;
      this.blockBtnPayment = false;
    } else {
      if(withCardSelected){
        this.checkoutresa(false, true);
      } else {
        this.doPayment()
      }
    }
  }

  getMatchId(){
    let counter = 0;
        let hasMatchId = false;
        const interval = setInterval(() => {
          this.matchIdStarted = true;
          if (counter < 30 && !hasMatchId) {
            console.log("getCart getMatchId")
            this.bookingService.getCart(this.cartId).subscribe({
              next: (res) => {
                let matchId = res.items[0].data.matchId
                console.log(res)
                console.log(matchId);
                if(matchId != undefined){
                  hasMatchId = true
                  this.matchId = matchId;
                }
              },
              error: (error) => {
                console.log(error);
              }
            });
            counter++;
          } else {

            clearInterval(interval);
          }
        }, 1000);
  }

  doPayment(creditCardSelected = false) {
    this.getMatchId();
    const eventId = this.eventId;
    var stripe = this.stripe;
    this.updateProfile();
    var self = this;
    if (
      self.userProfile.firstName == null ||
      (self.userProfile.firstName == '' && self.userProfile.lastName == null) ||
      self.userProfile.lastName == ''
    ) {
      self.errorName = true;
      self.error = true;
      this.blockBtnPayment = false;
    } else if (
      self.userProfile.email == null ||
      self.userProfile.email == '' ||
      !self.userProfile.email.match('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
    ) {
      self.errorMail = true;
      self.error = true;
      this.blockBtnPayment = false;
    } else {
      this.inPayment = true;
      self.errorName = false;
      self.errorMail = false;
      self.errorConditions = false;
      self.error = false;
      self.hasPaid = true;
      //event.preventDefault();
      self.isDisabled = true;
      if(!creditCardSelected){
        self.elements.submit();
      }
      if (self.clientSecret) {
        if (this.selectedCard) {
          // Use the stored card for payment
          stripe
            .confirmCardPayment(self.clientSecret, {
              payment_method: this.selectedCard.id
            })
            .then(function (result) {
              self.handlePaymentResult(result);
            })
            .catch((error) => {
              console.error(error);
              this.inPayment = false;
              this.blockBtnPayment = false;
            });
        } else {
          stripe
            .confirmPayment({
              clientSecret: self.clientSecret,
              elements: self.elements,
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    name: `${self.userProfile.firstName} ${self.userProfile.lastName}`,
                    email: self.userProfile.email
                  }
                },
                return_url: `https://${window.location.hostname}${
                  this.isWhiteLabel
                    ? `/reservation/${this.reservationId}`
                    : `/checkout/complete?eventId=${eventId}`
                }`
              },
              redirect: 'if_required'
            })
            .then(function (result) {
              self.handlePaymentResult(result);
            })
            .catch((error) => {
              console.error(error);
              this.inPayment = false;
              this.blockBtnPayment = false;
            });
        }
      } else if (!self.clientSecret) {
        if (!self.error) {
          self.paymentAnalytics();
          self.hasPaid = true;
          self.isPaidSuccess = true;
          self.newReservation = null;
          self.sessionStorageService.removeItem('voucher');
          self.sessionStorageService.removeItem('cart');
          self.modalService.dismissAll();
          sessionStorage.removeItem('stateService');
          if (self.isWhiteLabel) {
            self.router.navigate([`/reservation/${self.reservationId}`]);
          } else {
            self.router.navigate(['/checkout/complete'], {
              queryParams: { eventId: eventId }
            });
          }
        } else {
          self.hasPaid = false;
          self.isPaidSuccess = false;
          if (self.errorName) {
            this.errorPayment = this.translate.instant('checkout.error_name');
          } else if (self.errorMail) {
            this.errorPayment = this.translate.instant('checkout.error_mail');
          } else if (self.errorConditions) {
            self.errorPayment = 'Error Conditions';
          }
          this.inPayment = false;
          this.blockBtnPayment = false;
          self.tagManager.pushCheckoutFailed({
            center_id: self.center.id,
            activity_id: self.newReservation.activityId,
            price: self.newReservation.fullPrice,
            date: self.date,
            duration: self.duration,
            user_id: this.authFirebaseService.getUserDataId()
          });
        }
      }
    }
  }

  paymentAnalytics() {
    const resaEventGA = {
      transaction_id: this.reservationId,
      currency: this.newReservation.currency,
      value: this.newReservation.price / 100,
      items: [
        {
          item_id: this.newReservation.centerId,
          item_name: this.center.name,
          price: this.newReservation.price / 100,
          category: this.service.name,
          item_brand: this.newReservation.activityId,
          list_position: 0,
          quantity: 1
        }
      ]
    };

    this.tagManager.pushPurchaseGA(resaEventGA);

    const resaEventFB = {
      currency: this.newReservation.currency,
      value: this.newReservation.price / 100,
      contents: [
        {
          id: this.reservationId,
          quantity: this.service.name
        }
      ],
      content_type: 'product',
      external_id: this.authFirebaseService.getUserDataId()
    };

    this.tagManager.pushPurchaseFB(resaEventFB, this.reservationId);

    this.tagManager.pushCheckout({
      center_id: this.center.id,
      activity_id: this.newReservation.activityId,
      price: this.newReservation.fullPrice / 100,
      date: this.date,
      duration: this.duration,
      user_id: this.authFirebaseService.getUserDataId()
    });
  }

  updateProfile() {
    this.userService
      .updateProfile(
        this.userProfile.firstName,
        this.userProfile.lastName,
        this.userProfile.email
      )
      .subscribe({
        next: (_: User) => {
          this.tagManager.pushUpdateProfile(
            this.userProfile.firstName + ' ' + this.userProfile.lastName,
            this.userProfile.email
          );
        }, // success path
        error: (error) => console.error(error) // error path
      });
  }

  haveError(): boolean {
    if (
      this.error ||
      this.errorMail ||
      this.errorConditions ||
      this.errorName ||
      this.errorPayment
    ) {
      return (this.errorState = true);
    }
  }

  deleteCard(id: string) {
    this.bookingService.deleteCard(id).subscribe({
      next: (response) => {
        this.cards = this.cards.filter((card) => card.id !== id);
        this.message = response;
        this.selectDefaultCard();
        this.modalRef.close();
      },
      error: (error) => {
        this.message = error;
      }
    });
  }

  askDeleteCard(card: Card, content) {
    this.cardToDelete = card;

    this.modalRef2 = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    });

    this.modalRef2.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.deleteCard(this.cardToDelete.id);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  onCheckboxChange(event) {
    this.savedcheck = event.target.checked;
  }

  checkoutresa(selected = false,doPayement = false) {
    this.newReservation.paymentMethodType = this.paymentType;
    this.newReservation.changeSetupFutureUsage(this.savedcheck);
    console.log(this.newReservation)
    let dataBody = {
      "setupFutureUsage": this.newReservation.setupFutureUsage,
      "automaticPayment": this.newReservation.automaticPayment,
      "paymentMethodType": this.newReservation.paymentMethodType,
  }
    this.bookingService.checkoutCart(dataBody, this.cartId).subscribe({
      next: (reservationIntent) => {
        this.hasMatchId = false;
        console.log(reservationIntent)
        //this.matchId = reservationIntent.items
        this.clientSecret = reservationIntent.stripeClientSecret;
        console.log(this.clientSecret)
        this.reservationId = reservationIntent.id;
        // this.eventId = reservationIntent.eventId;
        this.loaded = true;
        console.log("enter foo2 !!!!", this.service)
        if (selected) {
          this.initStripeCreditCard();
        }
        if (doPayement) {
          this.doPayment(true);
        }


      },
      error: (error) => {
        this.loaded = true;
        this.isLoad = false;
        this.isPaidSuccess = false;
        this.errorPayment = error.error.message;
        this.blockBtnPayment = false;
      }
    });
  }

  newcard() {
    this.newcardselected = true;
    this.selectedCard = null;
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  shouldDisableButton(): boolean {
    return !(
      !this.inPayment &&
      !this.haveError() &&
      (!this.selectedCard || !this.newcardselected)
      && !this.blockBtnPayment
    );
  }

  open(content) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    });
  }

  checkoutNewCard() {
    this.selectNewCard = true;
    this.checkoutresa(true);
  }

  clearResa() {
    if (!this.isPaidSuccess) {
      this.tagManager.pushAbortPayment({
        center_id: this.center.id,
        reservation_id: this.reservationId,
        activity_id: this.newReservation.activityId,
        price: this.newReservation.fullPrice,
        date: this.date,
        duration: this.duration,
        user_id: this.authFirebaseService.getUserDataId()
      });
    }
  }

  selectCard(card: Card) {
    this.selectedCard = card;
    this.newcardselected = false;
  }

  handlePaymentResult(result) {
    var self = this;
    if (result.error) {
      console.error(result.error);
      self.hasPaid = false;
      self.isPaidSuccess = false;
      self.errorPayment = result.error.message;
      self.deleteResa(self.reservationId);
      this.inPayment = false;
      self.tagManager.pushCheckoutFailed({
        center_id: self.center.id,
        activity_id: self.newReservation.activityId,
        price: self.newReservation.fullPrice,
        date: self.date,
        duration: self.duration,
        user_id: this.authFirebaseService.getUserDataId()
      });
    } else {

      if (
        result.paymentIntent.status == 'succeeded' ||
        result.paymentIntent.status == 'requires_capture'
      ) {
        let counter = 0;
        const interval = setInterval(() => {
            if (counter < 30 && this.matchId === "") {
              counter++;
            } else {
              console.log(this.matchId)
              self.paymentAnalytics();
              self.hasPaid = true;
              self.isPaidSuccess = true;
              self.newReservation = null;
              self.sessionStorageService.removeItem('voucher');
              self.sessionStorageService.removeItem('cart');

              self.cardUpdateService.notifyCardUpdated();
              // if (self.isWhiteLabel) {
              //   self.router.navigate([`/reservation/${self.reservationId}`]);
              // } else {
              //   this.hasMatchId = true;
              //   self.router.navigate([`/match/${this.matchId}`]);
              // }
              this.hasMatchId = true;
              if(this.matchId !== ""){
                self.router.navigate([`/match/${this.matchId}`]);
                self.modalService.dismissAll();
              }else{
                self.hasPaid = false;
                self.isPaidSuccess = false;
                self.errorPayment = `Une erreur est survenue. Si le problème persiste n’hésite pas à contacter le support. Raison: fetching matchId error`;
              }

              clearInterval(interval);
            }
          }, 1000);
      } else {
        console.warn(result);
        self.hasPaid = false;
        self.isPaidSuccess = false;
        self.errorPayment = `le paiement a échoué avec le status ${result.status}`;
        self.deleteResa(self.reservationId);
        this.inPayment = false;
        self.tagManager.pushCheckoutFailed({
          center_id: self.center.id,
          activity_id: self.newReservation.activityId,
          price: self.newReservation.fullPrice,
          date: self.date,
          duration: self.duration,
          user_id: this.authFirebaseService.getUserDataId()
        });
      }
    }
  }

  shouldDisablePaiementButton(): boolean {
    return !this.loaded || this.inPayment || this.haveError() || this.blockBtnPayment;
  }

  selectDefaultCard(): void {
    if (this.cards && this.cards.length > 0) {
      this.selectedCard = this.cards[0];
      this.newcardselected = false;
    } else {
      this.newcardselected = true;
      this.selectedCard = null;
    }
  }

  newCardSelection() {
    if (!this.cards.length) {
      this.newcardselected = true;
    }
  }
}
