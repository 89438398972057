import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TOWN_LIST } from '../models/town-data';

@Component({
  selector: 'app-town-carousel',
  templateUrl: './town-carousel.component.html',
  styleUrls: ['./town-carousel.component.scss'],
  providers: [NgbCarouselConfig]
})
export class TownCarouselComponent implements OnInit, OnChanges {
  townList: any = TOWN_LIST.filter(menuItem => menuItem);
  townListByFour = [];
  cardShown = 2;
  arrowOutside = true;
  byFour = false;
  @Input() isMobileResolution: boolean;


  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    const dummmyEvent = { target: { innerWidth: window.innerWidth } };
    this.onResize(dummmyEvent);
    let j = 0;
    for (let i = 0; i < this.townList.length; i = i + 4) {
      this.townListByFour[j] = [];
      this.townListByFour[j].push(this.townList[i]);
      this.townListByFour[j].push(this.townList[i + 1]);
      this.townListByFour[j].push(this.townList[i + 2]);
      this.townListByFour[j].push(this.townList[i + 3]);
      j++;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isMobileResolution.currentValue != null && changes.isMobileResolution.currentValue !== undefined) {
      if (changes.isMobileResolution.currentValue === true) {
        this.cardShown = 2;
      } else {
        this.cardShown = 6;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const width = event.target.innerWidth;
    if (width < 576) {
      this.byFour = true;
      this.cardShown = 1;
      this.arrowOutside = false;
    } else if (width < 768) {
      this.byFour = false;
      this.cardShown = 3;
      this.arrowOutside = false;
    } else if (width < 992) {
      this.byFour = false;
      this.cardShown = 4;
      this.arrowOutside = true;
    } else {
      this.byFour = false;
      this.cardShown = 6;
      this.arrowOutside = true;
    }
  }
}
