import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WhitelabelComponent } from './whitelabel.component';
import { CenterPageComponent } from './center-page/center-page.component';
import { AuthGuardService } from '../auth-guard.service';
import { AccountComponent } from '../marketplace/account/account.component';
import { MyBookingsComponent } from '../marketplace/my-bookings/my-bookings.component';
import { ReservationComponent } from '../marketplace/reservation/reservation.component';
import { WhiteLabelLoginComponent } from './login/white-label-login.component';
import { LogoutComponent } from '../marketplace/logout/logout.component';
import { WhiteLabelCenterResolver } from './resolver/white-label-center-resolver';
import { PaiementComponent } from '../marketplace/paiement/paiement.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterFormComponent } from './register/register-form/register-form.component';
import { CommunityComponent } from '../marketplace/community/community.component';
import { MyWalletsComponent } from '../marketplace/my-wallets/my-wallets.component';
import { WLHomeComponent } from './home/home.component';
import {MyProfileComponent} from "../marketplace/my-profile/my-profile.component";
import {MyProfileResolver} from "../marketplace/my-profile/my-profile-resolver";
import { MatchComponent } from '../marketplace/match/match.component';
import {PaiementServiceComponent} from "../marketplace/paiement-service/paiement-service.component";
import {PaiementCartResolver} from "../marketplace/paiement/paiement-cart-resolver";

const routes: Routes = [
  {
    path: '',
    component: WhitelabelComponent,
    resolve: { center: WhiteLabelCenterResolver },
    children: [
      { path: '', component: WLHomeComponent, pathMatch: 'full' },
      { path: 'login', component: WhiteLabelLoginComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'reset', component: ResetPasswordComponent, data: {mode: 'reset',} },
      { path: 'retrieve', component: ResetPasswordComponent, data: {mode: 'retrieve',} },
      { path: 'register', component: RegisterFormComponent },
      {
        path: 'account',
        canActivate: [AuthGuardService],
        component: AccountComponent,
        data: { name: 'account', skipRouteLocalization: true },
        children: [
          { path: '', component: MyBookingsComponent },
          { path: 'wallets', component: MyWalletsComponent },
          { path: 'profile', component: MyProfileComponent, resolve: { userData: MyProfileResolver } },
        ]
      },
      { path: 'reservation/:id', component: ReservationComponent },
      { path: 'match/:id', component: MatchComponent },
      {
        path: 'checkout/:centerId/service/:serviceId',
        canActivate: [AuthGuardService],
        component: PaiementServiceComponent,
        pathMatch: 'full',
        data: { name: 'reservationdetails', token: '' }
      },
      {
        path: 'checkout/:centerId/:cartId',
        canActivate: [AuthGuardService],
        resolve: { cartResponse: PaiementCartResolver },
        component: PaiementComponent,
        pathMatch: 'full',
        data: { name: 'reservationdetails', token: '' }
      },
      { path: 'checkout/complete', redirectTo: 'account', pathMatch: 'full' },
      {
        path: 'club/:centerId/info',
        component: CenterPageComponent,
        pathMatch: 'full'
      },

      {
        path: 'club/:centerId/reservation',
        component: CenterPageComponent,
        data: { date: '' }
      },
      {
        path: 'club/:centerId/reservation/:sport',
        component: CenterPageComponent,
        data: { sport: '' }
      },
      { path: 'communities', component: CommunityComponent, pathMatch: 'full' },
      { path: '**', redirectTo: '', data: { skipRouteLocalization: true } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WhitelabelRoutingModule {}
