<ng-container>
  <app-loader *ngIf="isAuthentifiying"></app-loader>
</ng-container>

<div class="container-main" *ngIf="!isAuthentifiying">
  <app-top-navbar
    *ngIf="!isMobileResolution"
    [isMobileResolution]="isMobileResolution"
    [country]="country">
    <div class="d-flex" app-top-navbar-right>
      <button class="navbar-btn" (click)="downloadApp()">
        {{'menuComponent.download_app' | translate}}
      </button>
      <a [routerLink]="['/' + language, 'on-boarding']">
        <button class="navbar-btn" routerLinkActive="active">
          {{'menuComponent.add_your_center' | translate}}
        </button>
      </a>
    </div>
  </app-top-navbar>


  <div class="row w-100 h-100 m-0 content">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 mx-auto max-width" [ngClass]="{'mt-3' : !isMobileResolution}">

      <div id="card1">
        <div>
          <ng-container *ngIf="loginMode; then loginTitle; else subscribeTitle"></ng-container>
        </div>

        <div class="row providers m-0">
          <div class="col-12 col-md-8 mx-auto mt-2 p-0">
            <button class="btn-login google-btn-login" (click)="signUpGoogle()">
              <img class="me-2" alt="{{ 'login.google' | translate }}" ngSrc="v1677169402/assets/social/google.png" width="30" height="30">
              <span>{{ 'login.google' | translate }}</span>
            </button>
          </div>
          <div class="col-12 col-md-8 mx-auto mt-2 p-0">
            <button class="btn-login decathlon-btn-login" (click)="goToDecathlonLoginPage()">
              <img class="connection-logo me-2 decathlon-logo" ngSrc="v1677169402/assets/social/decathlon-logo.png" alt="Decathlon Login" width="30" height="30">
              <span>{{ 'login.decathlon' | translate }}</span>
            </button>
          </div>
          <div class="col-12 col-md-8 mx-auto mt-2 p-0">
            <button class="btn-login apple-btn-login" (click)="signUpApple()">
              <img  class="me-2" alt="{{ 'login.apple' | translate }}" ngSrc="v1677169402/assets/social/logo-apple.png" width="30" height="30">
              <span>{{ 'login.apple' | translate }}</span>
            </button>
          </div>
          <div class="col-12 col-md-8 mx-auto mt-2 p-0">
            <button class="btn-login fb-btn-login" (click)="signUpFb()">
              <img class="me-2" alt="{{ 'login.facebook' | translate }}" ngSrc="v1677169402/assets/social/facebook.png" width="30" height="30">
              <span>{{ 'login.facebook' | translate }}</span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="loginMode; then loginForm; else subscribeForm"></ng-container>
      </div>
    </div>

    <div class="navbardivMobile" *ngIf="isMobileResolution">
      <div class="d-flex flex-column menuMobile">
        <div>
          <a [routerLink]="['/' + country]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" fill="rgba(113,113,113,1)"/></svg>
          </a>
        </div>
        <a [routerLink]="['/' + country]" class="d-flex flex-column">
          {{'menuComponent.home' | translate}}
        </a>
      </div>
      <div class="d-flex flex-column menuMobile" [routerLink]="['/' + country, 'on-boarding']" routerLinkActive="active">
        <a [routerLink]="['/on-boarding']" class="iconActive">
          <img class="add_center_icon" ngSrc="v1677169402/assets/icon/Home_ADD.png" alt="add center" width="84" height="82">
        </a>
        <div class="mt-1" style="margin-top: 0rem !important;">
          <a [routerLink]="['/on-boarding']">
            {{'menuComponent.add_your_center' | translate}}
          </a>
        </div>
      </div>
      <div class="menuMobile">
        <a [routerLink]="['login-decathlon']">
          <div class="d-flex flex-column align-items-center">
            <img class="my_account_icon" ngSrc="v1677169402/assets/icon/COMPTE_AB_jaune.png" alt="add center" width="84" height="82">
          </div>
        </a>
        <div class="titleNavBar">{{'menuComponent.my_account' | translate}} </div>
      </div>
    </div>

  </div>
</div>
<ng-template #downlodApp let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="background: white;border: aliceblue;" aria-label="Close" (click)="onCloseModal(modal)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg>
    </button>
  </div>
  <div class="modal-body">
    <div class="buddyText">
      <img width="30%" src="assets/images/buddy_app.svg">
    </div>
    <div class='textPoup'>{{'menuComponent.download_app_store' | translate}}</div>
    <div class="d-flex" style="margin-bottom: 14%; margin-left: 5%;">
      <a href="{{'store.app_store_link' | translate}}" target="_blank" rel="noopener noreferrer"
         [ngClass]="{'me-3 ms-3': isMobileResolution, 'me-4': !isMobileResolution}">
        <img class="store-img-footer" alt="App Store" loading="lazy" src="{{'store.app_store_badge' | translate}}" width="564" height="168">
      </a>
      <a href="{{'store.play_store_link' | translate}}" target="_blank"
         rel="noopener noreferrer">
        <img class="store-img-footer" alt="Play Store" loading="lazy" src="{{'store.play_store_badge' | translate}}" width="564" height="168">
      </a>
    </div>
  </div>
</ng-template>


<ng-template #errorAuth let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="background: white;border: aliceblue;" aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">
    <div class='textPouperrorDark'>
      {{errorAuthMsg}}
    </div>
    <p class="textPouperror">{{errorAuthMail}}</p>
  </div>
</ng-template>

<ng-template #linkMailSent let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="background: white;border: aliceblue;" aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">
    <div class='textPouperrorDark'>
      {{'login.sended_link' | translate}}
      <span class="font-weight-bold">{{userMail}}</span>
    </div>
    <div class="d-flex">
      <img class="m-auto" ngSrc="v1677169402/assets/buddy/buddy_clin_doeil_165x165.png" width="165" height="165">
    </div>
  </div>
</ng-template>

<ng-template #checkMail let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="background: white;border: aliceblue;" aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">
    <div class='textPouperrorDark'>
      {{'login.sended_check_mail' | translate : {email: userMail} }}
    </div>
    <div class="d-flex">
      <img class="m-auto" ngSrc="v1677169402/assets/buddy/buddy_clin_doeil_165x165.png" width="165" height="165">
    </div>
  </div>
</ng-template>


<ng-template #loginTitle>
  <h1 class="title-card">{{ 'login.login_title' | translate }}</h1>
</ng-template>

<ng-template #subscribeTitle>
  <h1 class="title-card">{{ 'signup.signup_title' | translate }}</h1>
</ng-template>

<ng-template #magicPopUp let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="background: white;border: aliceblue;" aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">

    <div class=" col-12 col-md-8 mx-auto p-0">
      <input class="custom-input" placeholder="{{ 'login.email' | translate }}" type="email" [formControl]="magicLink">
      <div class="text-danger" *ngIf="(this.magicLink.hasError('required') || this.magicLink.hasError('email'))  && this.email.touched=== true">

        <div class="d-flex">
          <p class="mx-auto" *ngIf="this.magicLink.hasError('required')  && this.email.touched=== true">
            {{ 'assistance.email_required' | translate }}
          </p>
        </div>
        <div class="d-flex">
          <p class="mx-auto" *ngIf="this.magicLink.hasError('email') && this.email.touched=== true">
            {{ 'assistance.email_format' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class='textPouperror' style="font-size: 1rem !important;">
      {{errorAuthMsg}}
    </div>
    <div class="row connexion-option">
      <div class=" col-12 col-md-8 mx-auto mt-2 mb-3 content">
        <button class="email-link-btn" (click)="signUpMailLink()" [disabled]="isSubscribing" [ngClass]="{'btn-disabled' : isSubscribing}">
          {{ 'signup.send_magic_link' | translate }}
        </button>
      </div>
    </div>

  </div></ng-template>

<ng-template #loginForm>
  <div class="container-separate">
    <hr class="separate-line">
    <span class="separate-txt"> {{ 'login.or_connect' | translate }} </span>
    <hr class="separate-line">
  </div>

  <div class=" col-12 col-md-8 mx-auto p-0">
    <input class="custom-input" placeholder="{{ 'login.email' | translate }}" type="email" [formControl]="email">
    <div class="text-danger" *ngIf="(this.email.hasError('required') || this.email.hasError('email'))  && this.email.touched=== true">

      <div class="d-flex">
        <p class="mx-auto" *ngIf="this.email.hasError('required')  && this.email.touched=== true">
          {{ 'assistance.email_required' | translate }}
        </p>
      </div>
      <div class="d-flex">
        <p class="mx-auto" *ngIf="this.email.hasError('email') && this.email.touched=== true">
          {{ 'assistance.email_format' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class=" col-12 col-md-8 mx-auto p-0">
    <input class="custom-input password"
           placeholder="{{ 'login.password' | translate }}" [formControl]="password" type="password" required>
    <div class="text-danger" *ngIf="this.password.hasError('required') || this.errorMessage">
      <div class="d-flex">
        <p class="mx-auto mb-0" *ngIf="this.password.hasError('required') && this.password.touched=== true ">
          {{ 'assistance.password_required' | translate }}
        </p>
      </div>
      <div class="d-flex">
        <p class="mx-auto mb-0" *ngIf="this.errorMessage && this.password.touched=== true">
          {{errorMessage}}
        </p>
      </div>
    </div>
  </div>

  <div class=" col-12">
    <div class=" d-flex m-0">
      <a [routerLink]="['/forgot-pw']" class="mx-auto magic-link">
        <span class="">{{ 'login.forget_pw' | translate }}</span>
      </a>
    </div>
  </div>

  <div class="row connexion-option">
    <div class=" col-12 col-md-8 mx-auto mt-2 mb-3">
      <button class="email-link-btn" (click)="signIn()" [disabled]="isConnecting" [ngClass]="{'btn-disabled' : isConnecting}">
        {{ 'login.login_title' | translate }}
      </button>
    </div>
    <div class=" col-12 col-md-8 mx-auto p-0">
      <p class="magic-link" (click)="openMagicLinkPopUp()">
        {{ 'signup.magic_link' | translate}}
      </p>
    </div>

    <div class=" col-12">
      <div class="container-forget-pwd d-flex mt-3">
        <a [routerLink]="['/sign-up']" class="mx-auto">
          <span class="forget-pwd">{{ 'login.signup' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #subscribeForm>
  <div class="container-separate">
    <hr class="separate-line">
    <span class="separate-txt"> {{ 'login.or_subscribe' | translate }} </span>
    <hr class="separate-line">
  </div>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons"rel="stylesheet"/>
  <div class=" col-12 col-md-8 mx-auto p-0">
    <input class="custom-input" placeholder="{{ 'login.email' | translate }}" type="email" [formControl]="email">
    <div class="text-danger" *ngIf="(this.email.hasError('required') || this.email.hasError('email'))  && this.email.touched=== true">

      <div class="d-flex">
        <p class="mx-auto" *ngIf="this.email.hasError('required')  && this.email.touched=== true">
          {{ 'assistance.email_required' | translate }}
        </p>
      </div>
      <div class="d-flex">
        <p class="mx-auto" *ngIf="this.email.hasError('email') && this.email.touched=== true">
          {{ 'assistance.email_format' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class=" col-12 col-md-8 mx-auto p-0">
    <input class="custom-input password"
           placeholder="{{ 'login.password' | translate }}" [formControl]="password" type="password" required>
    <div class="text-danger">

      <div *ngIf="password.touched">
        <div class="text-ctr">
          <i class="material-icons" [ngClass]="password.hasError('required') || password.hasError('minlength')  ? 'text-danger-custom' : 'text-success-custom'">
            {{ password.hasError('required') || password.hasError('minlength') ? 'close' : 'done' }}
          </i>
          {{ 'resetpw.nbcaracter' | translate }}
        </div>
        <div class="text-ctr">
          <i class="material-icons" [ngClass]="password.hasError('required') || password.hasError('hasNumber') ? 'text-danger-custom' : 'text-success-custom'">
            {{ password.hasError('required') || password.hasError('hasNumber') ? 'close' : 'done' }}
          </i>
          {{ 'resetpw.nbNumber' | translate }}
        </div>
        <div class="text-ctr">
          <i class="material-icons" [ngClass]="password.hasError('required') || password.hasError('hasCapitalCase')  ? 'text-danger-custom' : 'text-success-custom'">
            {{ password.hasError('required') || password.hasError('hasCapitalCase') ? 'close' :'done' }}
          </i>
          {{ 'resetpw.nbCapitalCarcter' | translate }}
        </div>

        <div class="text-ctr">
          <span class="material-icons" [ngClass]="password.hasError('required') || password.hasError('hasSmallCase')  ? 'text-danger-custom' : 'text-success-custom'">
          {{ password.hasError('required') || password.hasError('hasSmallCase') ? 'close' :'done' }}
         </span>
          {{ 'resetpw.nbSmalCaracter' | translate }}
        </div>

        <div class="text-ctr">
          <i class="material-icons" [ngClass]="password.hasError('required') || password.hasError('hasSpecialCharacters') ? 'text-danger-custom' : 'text-success-custom'">
            {{ password.hasError('required') || password.hasError('hasSpecialCharacters') ? 'close' : 'done' }}
          </i>
          {{ 'resetpw.nbSpecialCaracter' | translate }}
        </div>
      </div>

    </div>
  </div>

  <div class="d-flex">
    <p class="mx-auto mb-0 text-danger-custom" *ngIf="this.errorMessage">
      {{errorMessage}}
    </p>
  </div>

  <div class="row connexion-option">
    <div class=" col-12 col-md-8 mx-auto mt-2">
      <button class="email-link-btn" (click)="signUp()" [disabled]="isSubscribing" [ngClass]="{'btn-disabled' : isSubscribing}">
        {{ 'signup.signup_title' | translate }}
      </button>
    </div>
    <div class=" col-12 mb-3">
      <div class="container-forget-pwd d-flex">
        <a [routerLink]="['/login']" class="mx-auto">
          <span class="forget-pwd">{{ 'login.already_account' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-template>
