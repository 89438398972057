import { Component } from '@angular/core';
import { Center } from 'src/app/marketplace/models/center';
import { WhiteLabelCenterService } from '../service/white-label-center.service';
import { NavbarContent } from '../service/white-label-navbarr.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class WLHomeComponent {
  centers: Center[] = [];
  showNavBarData: NavbarContent;
  isMobileResolution = false;
  showFooter = true;

  constructor(private whiteLabelCenterService: WhiteLabelCenterService) {
    this.whiteLabelCenterService
      .getCenterByDomain('forest-hill.fr')
      .subscribe((centers) => {
        this.centers = centers;
      });
  }
}
