import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {
  ActivityInfo,
  ACTIVITY_LIST,
  FITNESS_ACTIVITY_LIST,
  RAQUETTE_ACTIVITY_LIST
} from '../models/activity-list';
import { Amenity } from '../models/amenities';
import { Center } from '../models/center';
import { AddressService } from '../service/address.service';
import { CentersService } from '../service/center.service';
import { FavoriteService } from '../service/favorite.service';
import { SearchService } from '../service/search.service';
import { SeoService } from '../service/seo.service';
import { SelectedSportService, SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss'],
  providers: [AddressService, NgbNavConfig, CentersService, FavoriteService]
})
/* eslint-disable */
export class CenterComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  headerPhoto: string;
  amenities: Amenity[] = [];
  isShareOpen = false;
  isModalOpen = false;
  metaTitle: string;
  getActivty = '';
  // pour la recherche des images des sports
  activityList: any = ACTIVITY_LIST.filter((menuItem) => menuItem);

  // tableau des activités proposées dans page 'infos du club'
  facilitiesBySport = new Object();
  facilities: any[];

  country = '';
  loading = false;

  //favorite button
  favorite: boolean = false;

  // onglet 'info' du club
  activeNav = 1;

  searchImgSrc = '/assets/images/search-2-line.png';
  centerId: string;
  googleMapLink: string;
  center: Center;
  fav = false;
  isMobileResolution = true;
  displayReviewsPage = false;
  centerCoord: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };
  markers = [];
  dataAvailable: boolean;
  displayLongDescription: boolean;
  error: any;
  errorMessage: any;
  headerBackground: any;
  selectedSport = '';
  jsonld: any;
  dateSlots = '';
  isEspangole: boolean = false;
  res: boolean = false;
  sportName: boolean = false;
  isThereGolf: boolean = false;
  translatedPathSportCity: string | any[];
  translatedPathReviews: string | any[];
  translatedPathBooking: string | any[];
  slug: string;
  urlPath: string;
  centerSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private selectedSportService: SelectedSportService,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private centersService: CentersService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private seo: SeoService,
    private cdref: ChangeDetectorRef,
    private searchService: SearchService,
    private userPreferenceService: UserPreferenceService,
    private localize: LocalizeRouterService,
    private favoriteService: FavoriteService,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });

    this.selectedSportService.changeEmitted$.subscribe((selectedSport) => {
      this.selectedSport = selectedSport;
      this.setMetaTile();
    });
  }

  ngOnInit(): void {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.translatedPathSportCity =
      this.localize.translateRoute('reservation-sport');
    this.translatedPathReviews = this.localize.translateRoute('reviews');
    this.translatedPathBooking = this.localize.translateRoute('booking');
    if (this.userPreferenceService.getCountry() === 'es') {
      this.isEspangole = true;
    }
    if (this.route.snapshot.routeConfig.path === ':id/reservations') {
      this.activeNav = 2;
      if (this.route.snapshot.queryParams.date !== undefined) {
        this.dateSlots = this.route.snapshot.queryParams.date;
      }
    }
    if (
      this.route.snapshot.routeConfig.path ===
      ':id/' + this.localize.translateRoute(`booking`) + '/:sport'
    ) {
      this.activeNav = 2;
      this.res = true;
      this.sportName = true;
      this.selectedSport = this.route.snapshot.params.sport;
    }

    if (
      this.route.snapshot.routeConfig.path ===
      ':id/' + this.localize.translateRoute(`booking`)
    ) {
      this.activeNav = 2;
      this.res = true;
    }

    if (
      this.route.snapshot.routeConfig.path ===
      ':id/' + this.localize.translateRoute(`reviews`)
    ) {
      this.activeNav = 3;
    }
    const slug = this.route.snapshot.paramMap.get('id');
    this.centerId = slug.substring(5, slug.length);
    this.favorite = this.favoriteService.isFavorite(this.centerId);
    if (slug.startsWith('club') && this.centerId) {
      const idCenter = this.centerId;
      this.dataAvailable = false;
      this.getCenter(idCenter);
    } else if (slug.startsWith('reservation-')) {
      this.locate(slug);
    } else {
      this.router.navigate(['/']);
    }
    this.urlPath = `${this.country}/club-${this.centerId}/${this.translatedPathBooking}`;
  }

  ngOnDestroy() {
    //this.centerSubscription.unsubscribe();
  }

  getcolor(): string {
    return '3px solid red';
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.addMarker();
      if (
        window.innerWidth <= 768 ||
        this.deviceService.isMobile() ||
        this.deviceService.isTablet()
      ) {
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }
    }
    this.cdref.detectChanges();
  }

  getCenter(centerId: string): void {
    this.loading = true;
    this.centersService.getCenter(centerId).subscribe({
      next: (center) => {
        this.center = center;
        if (
          this.router.url.split('/').length > 2 &&
          this.router.url.split('/')[1].length === 2 &&
          this.center.address.country.toLocaleLowerCase() === 'fr' &&
          this.router.url.match(this.center.getPath())
        ) {
          this.router.navigate([this.center.getPath()]);
        }
        if (
          this.center.address.country.toLocaleLowerCase() !== 'fr' &&
          this.router.url.split('/').length > 2 &&
          this.router.url.split('/')[1].length === 2
        ) {
          if (
            this.router.url.split('/')[1] !==
            this.center.address.country.toLocaleLowerCase()
          ) {
            this.router.navigate([this.center.getPath()]);
          }
        }
        if (this.center.activities.length === 1) {
          this.selectedSport = this.center.activities[0].id;
          if (this.activeNav === 2) {
            this.router.navigate([
              this.center.getPath(),
              'reservation',
              this.selectedSport
            ]);
          }
        }
        this.slug = this.searchService.formatSlugCity(this.center.address.city);

        // voir si photos sont affichées en prod
        this.headerPhoto = this.center.headerPhoto['1120x560'];
        this.headerBackground = this.sanitizer.bypassSecurityTrustStyle(
          `background: url('${this.center.headerPhoto['1120x560']}') no-repeat; background-size: cover`
        );

        this.createGoogleMapLink();
        this.listCenterFacilities();
        this.initJsonld();
        this.centerCoord = {
          lat: +this.center.location.lat,
          lng: +this.center.location.lon
        };
        this.amenities = this.center.amenities;
        // pour avoir un affichage justifié des amenities
        if (!this.isMobileResolution && this.amenities.length % 4 !== 0) {
          while (this.amenities.length % 4 !== 0) {
            this.amenities.push(null);
          }
        }
      },
      error: (error) => {
        if (error.status === 404) {
          this.errorMessage = 'Center not found';
          this.router.navigate([
            `${this.userPreferenceService.getCountry()}/${
              this.translatedPathSportCity
            }/${this.getCity()}`
          ]);
        } else {
          this.errorMessage = 'An error has occured';
        }
      }
    });
  }

  getCity(): String {
    if (this.userPreferenceService.getCountry() === 'es') {
      return 'huelva-21001';
    } else {
      return 'paris-75000';
    }
  }

  createGoogleMapLink(): void {
    this.googleMapLink = `https://www.google.com/maps/search/?api=1&query=${this.center.location.lat},${this.center.location.lon}`;
  }

  listCenterFacilities(): void {
    this.facilities = [];
    this.centersService.listCenterFacilities(this.center.id).subscribe({
      next: (facilities) => {
        this.facilities = facilities;
        this.createFacilitiesListBySport();

        this.dataAvailable = true;
        this.loading = false;
      },
      error: (error) => {
        console.error(error.message);
        this.loading = false;
      }
    });
  }

  // pour l'affichage des activités proposées dans l'onglet 'infos du club'
  // affichées par le type de sport
  createFacilitiesListBySport(): void {
    for (const acti of this.activityList) {
      this.facilitiesBySport[acti.activity] = [];
    }
    this.setMetaTile();
  }

  setMetaTile() {
    this.metaTitle = this.generateMetaDataTitle();
    const desc = this.center.metaDescription
      ? this.center.metaDescription
      : this.center.shortDescription;
    const metaDescription = `${this.metaTitle}. ${desc}`;

    this.seo.generateTags({
      title: this.metaTitle,
      description: metaDescription,
      image: this.center.headerPhoto ? this.center.headerPhoto['840x420'] : ''
    });
  }

  generateMetaDataTitle(): string {
    let params: object = {
      center: this.center.name,
      place: this.center.neighborhoodName
    };
    const defaultTitle = this.center.metaTitle
      ? this.center.metaTitle
      : this.translate.instant('detail.info_title', params);
    switch (this.activeNav) {
      case 1:
        return defaultTitle;
      case 2:
        let traduction: string;
        if (this.selectedSport && this.route.snapshot.params.sport) {
          if (this.selectedSport === 'golf') {
            traduction = 'detail.reservation_golf';
          } else if (this.center.activities?.length > 0) {
            const sportRaquette: string[] = RAQUETTE_ACTIVITY_LIST.map(
              (activity: ActivityInfo) => activity.activity
            );
            const sportFitness: string[] = FITNESS_ACTIVITY_LIST.map(
              (activity: ActivityInfo) => activity.activity
            );
            if (sportRaquette.includes(this.selectedSport)) {
              traduction = 'detail.reservation_raquette';
              params = { ...params, sport: this.selectedSport };
            } else if (sportFitness.includes(this.selectedSport)) {
              traduction = 'detail.reservation_fitness';
            } else {
              traduction = 'detail.reservation';
            }
          } else {
            traduction = 'detail.reservation';
          }
        } else {
          traduction = 'detail.reservation_place';
        }
        return this.translate.instant(traduction, params);
      case 3:
        return this.translate.instant('detail.avis_title', params);
      default:
        return defaultTitle;
    }
  }

  // crée le pin marker du club sur la map
  addMarker(): void {
    this.markers.push({
      position: {
        lat: this.centerCoord.lat,
        lng: this.centerCoord.lng
      },
      options: {
        // animation: google.maps.Animation.DROP,
        icon: '/assets/images/pin.png'
      }
    });
  }

  onCloseReviewPage(newItem: boolean): void {
    this.displayReviewsPage = newItem;
  }

  // affiche l'onglet 'avis'
  onDisplayReviews(): void {
    if (!this.isMobileResolution) {
      if (this.center.ratingCount > 0) {
        this.activeNav = 3;
      }
    } else {
      if (this.center.ratingCount > 0) {
        this.displayReviewsPage = !this.displayReviewsPage;
      }
    }
  }
  openModal() {
    this.isModalOpen = true;
    document.body.style.overflowY = 'hidden';
  }

  closeModal() {
    this.isModalOpen = false;
    document.body.style.overflowY = 'inherit';
  }
  openShareModal(): void {
    this.isShareOpen = true;
    document.body.style.overflowY = 'hidden';
  }

  closeShareModal(): void {
    this.isShareOpen = false;
    document.body.style.overflowY = 'inherit';
  }

  fbShare(): void {
    {
      const url = window.location.href;
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + url,
        'facebook-share-dialog',
        'width=800,height=600'
      );
    }
  }

  messengerShare(): void {
    {
      const url = window.location.href;
      const appId = '303254103213707';
      if (window.matchMedia('(min-width: 1024px').matches) {
        window.open(
          'https://www.facebook.com/dialog/send?app_id=' +
            appId +
            '&link=' +
            url +
            '&redirect_uri=' +
            url,
          'facebook-share-dialog',
          'width=800,height=600'
        );
      } else {
        window.open(
          'fb-messenger://share?link=' +
            encodeURIComponent(url) +
            '&app_id=' +
            encodeURIComponent(appId),
          'facebook-share-dialog',
          'width=800,height=600'
        );
      }
    }
  }

  twitterShare(): void {
    {
      const url = window.location.href;
      const text = this.translate.instant('detail.twitter_share');

      window.open(
        'https://twitter.com/share?url=' +
          url +
          '&text=' +
          encodeURIComponent(text) +
          '',
        'twitter-share-dialog',
        'width=800,height=600'
      );
    }
  }

  formatImageSchema(array: any[]): any[] {
    const newArr = Object.keys(array).map((val) => {
      return {
        '@type': 'ImageObject',
        caption: val,
        url: array[val]
      };
    });
    return newArr;
  }

  initJsonld(): void {
    this.jsonld = {
      '@context': 'https://schema.org',
      '@type': 'SportsClub',
      '@id': `https://www.anybuddyapp.com/club-${this.center.id}`,
      name: this.center.name,
      image: this.formatImageSchema(this.center.headerPhoto),
      logo: this.formatImageSchema(this.center.profilePhoto),
      url: `https://www.anybuddyapp.com/club-${this.center.id}`,
      currenciesAccepted: 'EUR',
      paymentAccepted: 'Credit Card',
      priceRange: '$',
      address: {
        '@type': 'PostalAddress',
        streetAddress: this.center.address.line1,
        addressLocality: this.center.address.city,
        postalCode: this.center.address.postalCode,
        addressCountry: this.center.address.country
      },
      telephone: this.center.phoneNumber,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: this.center.location.lat,
        longitude: this.center.location.lon
      }
    };
  }

  public locate(slug): void {
    this.loading = true;
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const post =
              position.coords.latitude + ',' + position.coords.longitude;
            this.searchService.getGeoname(post).subscribe((res) => {
              this.router.navigate(['/' + slug + '/' + res['slug']]);
              this.loading = false;
            });
          },
          (error) => {
            this.router.navigate(['/' + slug + '/paris-75000']);
            this.loading = false;
            console.error(
              'Error code: ' +
                error.code +
                '<br /> Error message: ' +
                error.message
            );
          }
        );
      } else {
        this.router.navigate(['/' + slug + '/paris-75000-fr']);
        this.loading = false;
      }
    }
  }

  reloadPageReviews(): string {
    let url: string;
    let translatedPath: any = this.localize.translateRoute(`reviews`);
    url = '/' + this.center.getPath() + '/' + translatedPath;
    return url;
  }

  setFavorite() {
    this.favoriteService.addOrRemoveFavoriteIfExist(this.centerId);
    this.favorite = this.favoriteService.isFavorite(this.centerId);
  }

  newSportSelected(sport: string, updateLocation = false): void {
    console.log('newSportSelected#1', sport);
    this.selectedSport = sport;
    this.setMetaTile();
    if(updateLocation) {
      this.router.navigate([
        this.center.getPath(),
        'reservation',
        this.selectedSport
      ]);
    }
  }

  getPolicyCancellationText(cancellationPolicy: any): string {
    if (
      cancellationPolicy.offPeakIsCancellable &&
      cancellationPolicy.peakIsCancellable
    ) {
      return this.translate.instant('center.policy_refund_back_hours', {
        timeFull: cancellationPolicy.peakInString,
        timeLess: cancellationPolicy.offPeakInString
      });
    } else if (
      !cancellationPolicy.offPeakIsCancellable &&
      cancellationPolicy.peakIsCancellable
    ) {
      return this.translate.instant('center.policy_refund_back_hours_full', {
        timeFull: cancellationPolicy.peakInString
      });
    } else if (
      cancellationPolicy.offPeakIsCancellable &&
      !cancellationPolicy.peakIsCancellable
    ) {
      return this.translate.instant('center.policy_refund_back_hours_less', {
        timeLess: cancellationPolicy.offPeakInString
      });
    } else {
      return this.translate.instant('center.policy_no_refund_back');
    }
  }
}
