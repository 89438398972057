import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Activity } from '../../../models/activity';
import { ActivityService } from '../../../service/activity.service';
import { CentersService } from '../../../service/center.service';
import { SharedService } from '../../../service/shared.service';
import { TagManagerService } from '../../../service/tag-manager.service';
import { BreakpointService } from '../../../../shared/services/breakpoint.service';
import {fromEvent} from "rxjs";

export class FilterContent {
  filters: string;
  servicesTypes: string;
  sports: string;
  partySize: number;

  constructor(
    filters: string,
    servicesTypes: string,
    sports: string,
    partySize: number
  ) {
    this.filters = filters;
    this.servicesTypes = servicesTypes;
    this.sports = sports;
    this.partySize = partySize;
  }
}

@Component({
  selector: 'app-activity-availability-filter',
  templateUrl: './activity-availability-filter.component.html',
  styleUrls: ['./activity-availability-filter.component.scss']
})
export class ActivityAvailabilityFilterComponent implements OnInit, AfterViewChecked {
  filtersCharacteristiques;
  @ViewChild('sportSlider', { static: false }) sportSlider;
  public isScrollableSport = false;
  @Input() centerId;
  @Input() sportSelected;
  @Input() url;
  @Input() isWhiteLabel: boolean;
  @Output() newSportSelectedEvent = new EventEmitter<string>();
  @Output() filterUpdated = new EventEmitter<FilterContent>();
  activePanelIds: string[];

  listSports: Activity[] = [];
  sports = [];

  sportsSelected = [];
  sportList = [];
  activitiyList = [];
  filterForm: UntypedFormGroup;
  previousFilterForm: UntypedFormGroup;

  subActivities = [];
  servicesTypes = [];

  subActivitiesSelected = ['all'];
  servicesTypesSelected = ['all'];
  isMobileResolution = true;

  constructor(
    private fb: UntypedFormBuilder,
    private activityService: ActivityService,
    private centersService: CentersService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private tagManager: TagManagerService,
    private breakpointService: BreakpointService,
    private cdr: ChangeDetectorRef,
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    console.log('this.isWhiteLabel', this.isWhiteLabel);
    this.initFilterForm();
    this.getCenterActivities();
    this.openPanelsByDefault();
    fromEvent(window, 'resize').subscribe(() => {
      this.setScrollableSport();
    });
  }

  ngAfterViewChecked(): void {
    this.setScrollableSport();
    this.cdr.detectChanges();
  }

  setScrollableSport(): void {
    if (
      this.sportSlider.nativeElement.scrollWidth >
      this.sportSlider.nativeElement.clientWidth
    ) {
      this.isScrollableSport = true;
    } else {
      this.isScrollableSport = false;
    }
  }

  resetFilterSportACtivity(): void {
    this.subActivitiesSelected = ['all'];
    this.servicesTypesSelected = ['all'];
  }

  getCenterActivities(): void {
    this.activityService
      .getActivitiesByCenter(this.centerId)
      .subscribe((value) => {
        this.listSports = value;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.sports = value['activities'];

        if (this.sports.length === 1) {
          this.changeSelectedSport(this.sports[0].id);
        }

        const element = this.sports.find(
          (sport) => sport.id === this.sportSelected
        );
        if (element && element.activities) {
          this.activitiyList = element.activities;
          this.subActivities = element.activities;
        } else {
          this.activitiyList = [];
          this.subActivities = [];
          this.sports.forEach((s) => {
            if (
              s &&
              s.activities &&
              s.activities.findIndex((i) => i.id === this.sportSelected) !== -1
            ) {
              this.activitiyList = s.activities;
              this.subActivities = s.activities;
              const sport = this.sportSelected;
              this.sportSelected = s.id;
              this.selectServiceTypeOrActivity(
                this.subActivitiesSelected,
                sport
              );
            }
          });
        }
        this.getActivityFilter();
      });
  }

  getActivityFilter(): void {
    if (this.sportSelected) {
      this.centersService
        .getFilter(this.centerId, this.sportSelected)
        .subscribe((value1) => {
          this.servicesTypes = value1;
          this.extractCharacteristiques();
          this.initFilterForm();
        });
    }
  }

  extractCharacteristiques(): void {
    const servicesTypeLen = this.servicesTypes.length;
    this.initFilterForm();
    if (servicesTypeLen === 1) {
      this.setFiltersCharacteristiques(
        this.servicesTypes[0].resourceCharacteristicTypes
      );
    } else if (servicesTypeLen > 1) {
      this.mergeFiltersCharacteristiques();
    } else {
      this.servicesTypes = [];
      this.filtersCharacteristiques = [];
    }
    this.openPanelsByDefault();
  }

  setFiltersCharacteristiques(filterCharacteristique): void {
    this.filtersCharacteristiques = filterCharacteristique;
  }

  mergeFiltersCharacteristiques(): void {
    this.filtersCharacteristiques = [];
    const isAll =
      this.servicesTypesSelected.findIndex((s) => s === 'all') !== -1;
    this.servicesTypes.forEach((serviceType) => {
      if (
        isAll ||
        this.servicesTypesSelected.findIndex((c) => c === serviceType.id) !== -1
      ) {
        serviceType.resourceCharacteristicTypes.forEach((characteristique) => {
          const index = this.filtersCharacteristiques.findIndex(
            (char) => char.id === characteristique.id
          );
          if (index === -1) {
            this.filtersCharacteristiques.push(characteristique);
          }
        });
      }
    });
  }

  initFilterForm(): void {
    this.filterForm = this.fb.group({
      filterChoices: new UntypedFormArray([])
    });
  }

  changeSelectedSport(id: any): void {
    this.sportSelected = id;
    const element = this.sports.find((sport) => sport.id === id);
    if (element && element.activities) {
      this.activitiyList = element.activities;
      this.subActivities = element.activities;
    } else {
      this.activitiyList = [];
      this.subActivities = [];
      this.sports.forEach((s) => {
        if (
          s &&
          s.activities &&
          s.activities.findIndex((i) => i.id === this.sportSelected) !== -1
        ) {
          this.activitiyList = s.activities;
          this.subActivities = s.activities;
          this.subActivitiesSelected = ['all'];
        }
      });
    }
    this.getActivityFilter();
    if (element && element.activities) {
      this.activitiyList = element.activities;
    } else {
      this.activitiyList = [];
    }
    this.tagManager.pushSelectSport({
      activity_id: this.sportSelected
    });
    this.newSportSelectedEvent.emit(id);
  }

  selectServiceTypeOrActivity(array: string[], id: string): void {
    if (id !== 'all') {
      const pos = array.indexOf(id);
      if (pos === -1) {
        array.push(id);
        const posAll = array.indexOf('all');
        if (posAll !== -1) {
          array.splice(array.indexOf('all'), 1);
        }
      } else {
        array.splice(pos, 1);
      }
    }
    if (id === 'all' || array.length === 0) {
      array.length = 0;
      array.push('all');
    }
    this.updateFilter();
  }

  updateFilter(): void {
    const filter = new FilterContent(
      this.filterForm.controls.filterChoices.value.toString(),
      this.getTypes(),
      this.getSports(),
      0
    );
    this.filterUpdated.emit(filter);
  }

  getTypes(): string {
    if (
      this.servicesTypesSelected.length === 1 &&
      this.servicesTypesSelected.indexOf('all') !== -1
    ) {
      return '';
    }
    return this.servicesTypesSelected.toString();
  }

  getSports(): string {
    if (
      this.subActivitiesSelected.length === 1 &&
      this.subActivitiesSelected.indexOf('all') !== -1
    ) {
      return this.sportSelected;
    }
    return this.subActivitiesSelected.toString();
  }

  onCheckChange(event): void {
    const formArray: UntypedFormArray = this.filterForm.get(
      'filterChoices'
    ) as UntypedFormArray;
    if (event.target.checked) {
      formArray.push(new UntypedFormControl(event.target.value));
    } else {
      this.removeControle(formArray, event.target.value);
    }
    this.updateFilter();
  }

  removeControle(formArray, value): void {
    let i = 0;
    formArray.controls.forEach((ctrl: UntypedFormControl) => {
      if (ctrl.value === value) {
        formArray.removeAt(i);
        return;
      }
      i++;
    });
  }

  serviceTypeOrActivityIsSelected(array: string[], id: string): boolean {
    if (array.indexOf(id) !== -1) {
      return true;
    }
    return false;
  }

  openFilterModal(content): void {
    this.modalService.open(content, { centered: true });
  }

  senAvailabilitiesMobile(): void {
    this.modalService.dismissAll();
    this.updateFilter();
  }

  closeModalFilter(): void {
    this.modalService.dismissAll();
    this.filterForm = this.previousFilterForm;
  }

  openPanelsByDefault() {
    if (
      this.filtersCharacteristiques &&
      (this.sportSelected || this.sports.length === 1)
    ) {
      this.activePanelIds = this.filtersCharacteristiques
        .filter((characteristique, i) => characteristique.options.length >= 2)
        .map((_, i) => 'panel-' + i);
    } else {
      this.activePanelIds = [];
    }
  }
  onPrev(id: string): void {
    const element = this.sportSlider.nativeElement;
    element.scrollLeft =
      element.scrollLeft - (this.sportSlider.nativeElement.clientWidth + 20);
  }

  onNext(id: string): void {
    const element = this.sportSlider.nativeElement;
    element.scrollLeft =
      element.scrollLeft + (this.sportSlider.nativeElement.clientWidth - 20);
  }

}
