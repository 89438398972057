<app-menu [onBoardingPage]="onBoardingPage" [isMobileResolution]="false"></app-menu>

<div class="container mt-5">
  <div class="row header mx-0 mx-md-auto mb-3">
    <div class="col-12 col-lg-5 p-0">
      <div class="d-flex">
        <div class="bubble d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6 19h12V9.157l-6-5.454-6 5.454V19zm13 2H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM7.5 13h2a2.5 2.5 0 1 0 5 0h2a4.5 4.5 0 1 1-9 0z" fill="rgba(159,134,255,1)"/></svg>
          <div class="ms-2">
            {{ 'onBoardingComponent.for_club' | translate  }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="title">{{ 'onBoardingComponent.what_anybuddy' | translate  }}</h2>
        <p>{{ 'onBoardingComponent.what_anybuddy_intro' | translate  }}</p>
      </div>

      <div class="row d-block d-sm-flex buttons">
        <div class="col-12 col-sm-6 d-flex mb-3 mb-sm-0">
          <button class="my-btn btn-reference m-auto d-flex">
            <div class="d-flex m-auto">
              <svg id="Efficacity" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><defs><style>.cls-1 {fill: #ffc300;} .cls-2 {fill: none;} .cls-3 {fill: #fff;}</style></defs><circle class="cls-1" cx="45" cy="45" r="45"/><path class="cls-2" d="M17.71,17H73.77V73.07H17.71Z" transform="translate(-1 -1)"/><path class="cls-2" d="M33.12,5.45l50.2,25-25,50.21-50.2-25Z" transform="translate(-1 -1)"/><path class="cls-3" d="M30.1,57.13l14.65,7.28a15.21,15.21,0,0,1-11,3.68A15.19,15.19,0,0,1,30.1,57.13Zm25.28-1L57.2,63l-2,4L21.73,50.36l2-4,6.54-2.67,6-12.14c3.62-7.29,11.94-10.88,20.4-9.55,6.17,5.94,8.32,14.74,4.7,22Zm-8.6-14.2a4.67,4.67,0,1,0-2.1-6.26A4.66,4.66,0,0,0,46.78,41.94Z" transform="translate(-1 -1)"/></svg>
              <div class="m-auto" (click)="subscribe()">{{ 'onBoardingComponent.subscribe' | translate  }}</div></div></button>
        </div>
        <div class="col-12 col-sm-6 d-flex">
          <button class="my-btn btn-rdv m-auto" (click)="help()">{{ 'onBoardingComponent.take_rendez_vous' | translate  }}</button>
        </div>
      </div>

    </div>
    <div class="col-12 col-md-7 col-lg-6 p-0 mt-3 mt-lg-0">
      <img class="d-none d-md-block" style="height:85% !important" width="170%" ngSrc="v1677232454/assets/on_boarding/Image_header_desktop_page_club.png" width="1169" height="889">
      <img class="d-block d-md-none w-100 h-auto"  ngSrc="v1677232453/assets/on_boarding/Image_mobile_header_page_club.png" width="1169" height="889">
    </div>

  </div>
</div>

<div class="about">
  <div class="container">
    <div class="d-flex">
      <div class="bubble mt-4">{{ 'onBoardingComponent.about' | translate  }}</div>
    </div>
    <div class="py-4">
      <h4 class="title">{{ 'onBoardingComponent.some_numbers' | translate  }}</h4>
    </div>
    <div class="row boxes pb-5">
      <div class="col-6 col-sm-4 col-lg-2">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-maison.svg" width="101" height="78">
          </div>
          <h6 class="title">{{ 'onBoardingComponent.about_center' | translate  }}</h6>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-joueur.svg" width="100" height="77">
          </div>
          <h6 class="title mb-0">{{ 'onBoardingComponent.about_players' | translate  }}</h6>
          <h6 class="title mb-0">{{ 'onBoardingComponent.about_players_players' | translate  }}</h6>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mt-3 mt-sm-0">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-argent.svg" width="101" height="77">
          </div>
          <h6 class="title">{{ 'onBoardingComponent.about_money' | translate  }}</h6>
          <p class="description">{{ 'onBoardingComponent.about_money_text' | translate  }}</p>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mt-3 mt-lg-0">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-etoile.svg" width="111" height="79">
          </div>
          <h6 class="title">{{ 'onBoardingComponent.about_stars' | translate  }}</h6>
          <p class="description">{{ 'onBoardingComponent.about_stars_text' | translate  }}</p>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mt-3 mt-lg-0">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-coupe.svg" width="97" height="77">
          </div>
          <h6 class="title">{{ 'onBoardingComponent.about_google' | translate  }}</h6>
          <p class="description">{{ 'onBoardingComponent.about_google_text' | translate  }}</p>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mt-3 mt-lg-0">
        <div class="box">
          <div class="d-flex">
            <img class="img" ngSrc="v1681811741/assets/home/chiffres-icone-sports.svg" width="96" height="77">
          </div>
          <h6 class="title">{{ 'onBoardingComponent.about_sport' | translate  }}</h6>
          <p class="description">{{ 'onBoardingComponent.about_sport_text' | translate  }}</p>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container">

  <div class="fonctionalite my-4">
    <div class="d-flex">
      <div class="bubble ms-auto">{{ 'onBoardingComponent.functionality' | translate  }}</div>
    </div>
    <div class="py-4">
      <h4 class="title">{{ 'onBoardingComponent.discover_functionality' | translate  }}</h4>
    </div>


    <div class="row ">
      <div class="col-12 col-lg-8 picture-container d-none d-lg-block">
        <div *ngIf="acc.isExpanded('fonctionalite-2')">
          <h4>{{ 'onBoardingComponent.accesibility_title' | translate  }}</h4>
          <p>{{ 'onBoardingComponent.accesibility_text' | translate  }}</p>
        </div>
        <img [src]="'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677233214/assets/on_boarding/'+ selectedImage">
      </div>
      <div class="col-12 col-lg-4">

        <ngb-accordion [activeIds]="'fonctionalite-1'" [closeOthers]="true"  #acc="ngbAccordion" (panelChange)="changePicture($event)" >
          <ngb-panel id="fonctionalite-1" [cardClass]="acc.isExpanded('fonctionalite-1') ? 'accordion-custom-fonctionalite-opened' : 'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-1')" src="assets/images/newOnBoarding/Argument%201%20-%20icone%20calendrier.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%201%20-%20icone%20calendrier.svg">
                <span>{{ 'onBoardingComponent.sync_calendar_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-1') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.sync_calendar_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.sync_calendar_p2' | translate  }}</p>
              <p>{{ 'onBoardingComponent.sync_calendar_p3' | translate  }}</p>
              <p>{{ 'onBoardingComponent.sync_calendar_p4' | translate  }}</p>
              <a href="https://go.crisp.chat/chat/embed/?website_id=9b5110a7-ec05-4ef0-8d28-d9dad2348bd8" class="more-about">{{ 'onBoardingComponent.more_about' | translate  }}
                <svg style="margin: inherit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
              </a>
              <img class="d-lg-none w-100 h-auto" ngSrc="v1677233214/assets/on_boarding/Argument_1.png" width="1083" height="753">
            </ng-template>
          </ngb-panel>
          <ngb-panel id="fonctionalite-2" [cardClass]="acc.isExpanded('fonctionalite-2') ? 'accordion-custom-fonctionalite-opened' :'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-2')" src="assets/images/newOnBoarding/Argument%202%20-%20icone%20synchro.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%202%20-%20icone%20synchro.svg">
                <span><span>{{ 'onBoardingComponent.club_help' | translate  }}</span></span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img  class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-2') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.club_help_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.club_help_p2' | translate  }}</p>
              <p>{{ 'onBoardingComponent.club_help_p3' | translate  }}</p>

              <ngb-carousel class="mt-2 d-block d-lg-none" [showNavigationArrows]="true" [interval]="false" >
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper d-flex">
                    <img class="m-auto width-100" ngSrc="v1677233214/assets/on_boarding/argument_1_crop.png" alt="Random first slide" width="324" height="564">
                  </div>
                </ng-template>
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper d-flex">
                    <img class="m-auto width-100" ngSrc="v1677233214/assets/on_boarding/argument_2_crop.png" alt="Random first slide" width="329" height="564">
                  </div>
                </ng-template>
                <ng-template ngbSlide>
                  <div class="picsum-img-wrapper d-flex">
                    <img class="m-auto width-100" ngSrc="v1677233214/assets/on_boarding/argument_3_crop.png" alt="Random first slide" width="324" height="564">
                  </div>
                </ng-template>
              </ngb-carousel>

            </ng-template>
          </ngb-panel>
          <ngb-panel id="fonctionalite-3" [cardClass]="acc.isExpanded('fonctionalite-3') ? 'accordion-custom-fonctionalite-opened' :'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-3')" src="assets/images/newOnBoarding/Argument%203%20-%20icone%20flexibilite.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%203%20-%20icone%20flexibilite.svg">
                <span>{{ 'onBoardingComponent.flexibility' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-3') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.flexibility_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.flexibility_p2' | translate  }}</p>
              <a href="https://go.crisp.chat/chat/embed/?website_id=9b5110a7-ec05-4ef0-8d28-d9dad2348bd8" class="more-about">{{ 'onBoardingComponent.more_about' | translate  }}
                <svg style="margin: inherit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
              </a>
              <img class="d-lg-none w-100 h-auto" ngSrc="v1677233214/assets/on_boarding/Argument_3.png" width="1002" height="796">
            </ng-template>
          </ngb-panel>

          <ngb-panel id="fonctionalite-4" [cardClass]="acc.isExpanded('fonctionalite-4') ? 'accordion-custom-fonctionalite-opened' :'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-4')" src="assets/images/newOnBoarding/Argument%204%20-%20icone%20securite.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%204%20-%20icone%20securite.svg">
                <span>{{ 'onBoardingComponent.security' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-4') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.security_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.security_p2' | translate  }}</p>
              <p>{{ 'onBoardingComponent.security_p3' | translate  }}</p>
              <img class="d-lg-none w-100 h-auto" ngSrc="v1677233214/assets/on_boarding/Argument_4.png" width="944" height="623">
            </ng-template>
          </ngb-panel>
          <ngb-panel id="fonctionalite-5" [cardClass]="acc.isExpanded('fonctionalite-5') ? 'accordion-custom-fonctionalite-opened' :'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-5')" src="assets/images/newOnBoarding/Argument%205%20-%20icone%20oeil.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%205%20-%20icone%20oeil.svg">
                <span>{{ 'onBoardingComponent.visibility' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-5') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.visibility_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.visibility_p2' | translate  }}</p>
              <img class="d-lg-none w-100 h-auto" ngSrc="v1677233214/assets/on_boarding/Argument_5.png" width="847" height="564">
            </ng-template>
          </ngb-panel>
          <ngb-panel id="fonctionalite-6" [cardClass]="acc.isExpanded('fonctionalite-6') ? 'accordion-custom-fonctionalite-opened' :'accordion-custom-fonctionalite-closed'">
            <ng-template ngbPanelTitle>
              <img class="d-none d-lg-block" *ngIf="acc.isExpanded('fonctionalite-6')" src="assets/images/newOnBoarding/Argument%206%20-%20icone%20courbe.svg">
              <div class="accordion-button d-flex">
                <img class="d-lg-none" src="assets/images/newOnBoarding/Argument%206%20-%20icone%20courbe.svg">
                <span>{{ 'onBoardingComponent.rentability' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex d-lg-none">
                  <img class="m-auto" width="24" height="24" [src]="acc.isExpanded('fonctionalite-6') ? 'assets/images/newOnBoarding/subtract-line.svg' : 'assets/images/newOnBoarding/add-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p>{{ 'onBoardingComponent.rentability_p1' | translate  }}</p>
              <p>{{ 'onBoardingComponent.rentability_p2' | translate  }}</p>
              <p>{{ 'onBoardingComponent.rentability_p3' | translate  }}</p>
              <img class="d-lg-none w-100 h-auto" ngSrc="v1677233214/assets/on_boarding/Argument_6.png" width="886" height="651">
            </ng-template>
          </ngb-panel>

        </ngb-accordion>
      </div>
    </div>
  </div>

  <div class="d-flex mb-3">
    <div class="bubble">{{ 'onBoardingComponent.association' | translate  }}</div>
  </div>

  <div class="row association">

    <div class="col-12 col-lg-6 asso-info">
      <h2>{{ 'onBoardingComponent.association_title' | translate  }}</h2>
      <p>{{ 'onBoardingComponent.association_clubs' | translate  }}</p>
      <div>
        <div class="d-flex">
          <svg class="icones" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1.95-9H15v2h-4.95a2.5 2.5 0 0 0 4.064 1.41l1.7 1.133A4.5 4.5 0 0 1 8.028 13H7v-2h1.027a4.5 4.5 0 0 1 7.788-2.543L14.114 9.59A2.5 2.5 0 0 0 10.05 11z"/></svg>
          <p>{{ 'onBoardingComponent.association_p1' | translate  }}</p>
        </div>
        <div class="d-flex">
          <svg class="icones" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/></svg>
          <p>{{ 'onBoardingComponent.association_p2' | translate  }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 mt-3 mt-lg-0">
      <div class="iframe-container d-flex">
        <iframe class="m-auto" width="560" height="315" src="https://www.youtube.com/embed/wmS9PxFiZ4g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <div class="row club-prive lg-reverse">
    <div class="col-12 col-lg-7 reasons-cards-container">

      <div class="reasons-cards">

        <div class="reasons-card">
          <div class="reasons-card-content">
            <div class="py-3 d-flex">
              <div style="padding: 0 1rem">
                <h5 class="reasons-card-title"><img width="30px" ngSrc="v1677233214/assets/icon/Icone_guillemets.svg" width="41" height="29"></h5>
                <p class="reasons-card-text">{{ 'onBoardingComponent.olivier_comment' | translate  }}</p>
                <div><h4 class="name">{{ 'onBoardingComponent.olivier' | translate  }}</h4></div>
              </div>
              <div class="icon-container">
                <img ngSrc="v1677233214/assets/on_boarding/Visuel_Olivier.png" width="142" height="142">
              </div>
            </div>
          </div>
        </div>

        <div class="reasons-card">
          <div class="reasons-card-content">
            <div class="py-3 d-flex">
              <div style="padding: 0 1rem">
                <h5 class="reasons-card-title">
                  <img width="30px" ngSrc="v1677233214/assets/icon/Icone_guillemets.svg" width="41" height="29"></h5>
                <p class="reasons-card-text">{{ 'onBoardingComponent.isabelle_comment' | translate  }}</p>
                <div><h4 class="name">{{ 'onBoardingComponent.isabelle' | translate  }}</h4></div>
              </div>
              <div class="icon-container">
                <img ngSrc="v1677233214/assets/on_boarding/Photo_Isabelle.png" width="142" height="142">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="col-12 col-lg-5 private-info">
      <div class="d-flex mb-3">
        <div class="bubble ms-auto">{{ 'onBoardingComponent.private_club' | translate  }}</div>
      </div>
      <h2>{{ 'onBoardingComponent.private_club_title' | translate  }}</h2>
      <p>{{ 'onBoardingComponent.private_club_clubs' | translate  }}</p>
      <div>
        <div class="d-flex flow">
          <svg class="icones" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1.95-9H15v2h-4.95a2.5 2.5 0 0 0 4.064 1.41l1.7 1.133A4.5 4.5 0 0 1 8.028 13H7v-2h1.027a4.5 4.5 0 0 1 7.788-2.543L14.114 9.59A2.5 2.5 0 0 0 10.05 11z"/></svg>
          <p>{{ 'onBoardingComponent.private_club_p1' | translate  }}</p>
        </div>
        <div class="d-flex flow">
          <svg class="icones" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/></svg>
          <p>{{ 'onBoardingComponent.private_club_p2' | translate  }}</p>
        </div>
      </div>

    </div>
  </div>

  <div class="mb-4">
    <div class="d-flex mb-3">
      <div class="bubble">{{ 'onBoardingComponent.support' | translate  }}</div>
    </div>
    <div class="row faq">
      <div class="col-12 col-md-6 faq-pad">
        <h4>{{ 'onBoardingComponent.support_title' | translate  }}</h4>
        <p>{{ 'onBoardingComponent.support_p1' | translate  }}</p>
      </div>
      <div class="col-12 col-md-6">
        <ngb-accordion [closeOthers]="true"  #acc1="ngbAccordion">
          <ngb-panel id="static-1" [cardClass]="'accordion-custom'">
            <ng-template ngbPanelTitle>
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q1_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-1') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q1_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-2" [cardClass]="'accordion-custom'">
            <ng-template ngbPanelTitle>
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q2_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-2') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q2_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-3" [cardClass]="'accordion-custom'">
            <ng-template ngbPanelTitle>
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q3_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-3') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q3_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-4" [cardClass]="'accordion-custom'" >
            <ng-template ngbPanelTitle let-opened="opened">
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q4_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-4') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q4_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-5" [cardClass]="'accordion-custom'" >
            <ng-template ngbPanelTitle let-opened="opened">
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q5_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-5') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q5_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-6" [cardClass]="'accordion-custom'" >
            <ng-template ngbPanelTitle let-opened="opened">
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q6_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-6') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q6_anwser' | translate  }}</ng-template>
          </ngb-panel>
          <ngb-panel id="static-7" [cardClass]="'accordion-custom'" >
            <ng-template ngbPanelTitle let-opened="opened">
              <div class="accordion-button d-flex">
                <span>{{ 'onBoardingComponent.support_q7_title' | translate  }}</span>
                <div ngbPanelToggle class="btn btn-link ps-1 py-0 ms-auto d-flex">
                  <img width="24" height="24" [src]="acc1.isExpanded('static-7') ? 'assets/images/newOnBoarding/indeterminate-circle-line.svg' : 'assets/images/newOnBoarding/add-circle-line.svg'">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>{{ 'onBoardingComponent.support_q7_anwser' | translate  }}</ng-template>
          </ngb-panel>
        </ngb-accordion>

        <div class="more-question">
          <a href="https://support.anybuddyapp.com/fr/category/club-o55mgj">{{ 'onBoardingComponent.more_questions' | translate  }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row referencer mx-1 mx-md-0">
    <div class="col-12 col-lg-6">
      <h4 class="title">{{ 'onBoardingComponent.reference' | translate  }}</h4>
      <p class="information">{{ 'onBoardingComponent.reference_p1' | translate  }}</p>
      <div class="row d-block d-sm-flex buttons">
        <div class="col-12 col-sm-6 d-flex mb-3 mb-sm-0">
          <button class="my-btn btn-rdv rdv-color m-auto" (click)="help()">{{ 'onBoardingComponent.take_rendez_vous' | translate  }}</button>
        </div>
        <div class="col-12 col-sm-6 d-flex">
          <button class="my-btn btn-reference m-auto d-flex" (click)="subscribe()">
            <div class="d-flex m-auto">
              <svg id="Efficacity" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><defs><style>.cls-1 {fill: #ffc300;} .cls-2 {fill: none;} .cls-3 {fill: #fff;}</style></defs><circle class="cls-1" cx="45" cy="45" r="45"/><path class="cls-2" d="M17.71,17H73.77V73.07H17.71Z" transform="translate(-1 -1)"/><path class="cls-2" d="M33.12,5.45l50.2,25-25,50.21-50.2-25Z" transform="translate(-1 -1)"/><path class="cls-3" d="M30.1,57.13l14.65,7.28a15.21,15.21,0,0,1-11,3.68A15.19,15.19,0,0,1,30.1,57.13Zm25.28-1L57.2,63l-2,4L21.73,50.36l2-4,6.54-2.67,6-12.14c3.62-7.29,11.94-10.88,20.4-9.55,6.17,5.94,8.32,14.74,4.7,22Zm-8.6-14.2a4.67,4.67,0,1,0-2.1-6.26A4.66,4.66,0,0,0,46.78,41.94Z" transform="translate(-1 -1)"/></svg>
              <div class="my-auto" style="margin-left: .5rem">{{ 'onBoardingComponent.subscribe' | translate  }}</div></div></button>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6">
      <img class="w-100 h-auto" ngSrc="v1677232336/assets/on_boarding/sports_logo.png" width="722" height="370">
    </div>
  </div>

</div>


<!-- footer -->
<app-footer [isMobileResolution]="isMobileResolution"></app-footer>
