import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { UserPreferenceService } from '../../marketplace/service/user-preferences.service';
import { AuthFirebaseService } from '../../marketplace/service/auth-firebase.service';
import { SharedService } from '../../marketplace/service/shared.service';
import { Credentials } from '../../marketplace/models/credentials';
import { TranslateService } from '@ngx-translate/core';
import { WhiteLabelCenterService } from '../service/white-label-center.service';
import { WhiteLabelFooterService } from '../service/white-label-footer.service';
import { Center } from '../../marketplace/models/center';

@Component({
  selector: 'app-white-label-login',
  templateUrl: './white-label-login.component.html',
  styleUrls: ['./white-label-login.component.scss']
})
export class WhiteLabelLoginComponent implements OnInit {
  isMobileResolution = false;
  email: UntypedFormControl;
  password: UntypedFormControl;
  errorMessage: any;
  error = false;
  country = 'fr';
  language = '';
  errorAuthMsg = '';
  isConnecting = false;
  centers: Center[];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    public authService: AuthService,
    public authFirebaseService: AuthFirebaseService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private whiteLabelCenterService: WhiteLabelCenterService,
    private footerService: WhiteLabelFooterService,
    private route: ActivatedRoute
  ) {
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
    this.centers = this.whiteLabelCenterService.centers;
  }

  ngOnInit(): void {
    console.log(this.route.queryParams);
    this.route.queryParams.subscribe((params) => {
      const redirectUrl = params['redirectUrl'];
      if (redirectUrl) {
        console.log(redirectUrl);
      }
    });
    this.footerService.footerInvisible();
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }

    this.userPreferenceService.getlocalChangeCountry().subscribe((value) => {
      this.country = value;
      if (this.country !== 'fr') {
        this.language = this.country;
      }
    });

    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]);
    this.password = new UntypedFormControl('', [Validators.required]);
  }

  isMailValid(): boolean {
    this.email.markAsTouched();
    return this.email.valid;
  }

  isPasswordValid(): boolean {
    this.password.markAsTouched();
    return this.password.valid;
  }

  signIn() {
    this.error = false;
    this.errorMessage = null;
    const isEmailValid = this.isMailValid();
    const isPasswordValid = this.isPasswordValid();
    if (isEmailValid && isPasswordValid) {
      this.isConnecting = true;
      this.authService
        .authWithProvider(
          'foresthill',
          null,
          new Credentials(this.email.value, this.password.value)
        )
        .subscribe({
          next: (res) => {
            this.authFirebaseService
              .SignInWithCustomToken(res.body.accessToken)
              .then(() => {
                this.authFirebaseService.sendLoginManager = true;
                localStorage.setItem('sendLoginManager', 'true');
                this.navigateEndLogin();
              })
              .catch((error) => {
                console.error(error);
                this.error = true;
                this.isConnecting = false;
                this.errorAuthMsg = error.message;
              });
          },
          error: (error) => {
            console.error(error);
            this.error = true;
            this.isConnecting = false;
            this.errorMessage = error.error.message;
            this.errorAuthMsg = error.error.message;
          }
        });
    }
  }

  navigateEndLogin(): void {
    this.route.queryParams.subscribe((params) => {
      const redirectUrl = params['redirectUrl'];
      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.router.navigate(['/account']);
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalService.open(template);
  }

  public onCloseModal(modal: NgbActiveModal): void {
    modal.close();
  }

  getErrorMessage(form: UntypedFormControl, inputName: string): string {
    if (form.touched) {
      if (inputName === 'password' && this.errorMessage) {
        return this.errorMessage;
      }
      if (form.hasError('required') && inputName === 'email') {
        return this.translate.instant('assistance.email_required');
      } else if (form.hasError('required') && inputName === 'password') {
        return this.translate.instant('assistance.password_required');
      } else if (form.hasError('email')) {
        return this.translate.instant('assistance.email_format');
      } else return '';
    } else {
      return '';
    }
  }
}
