<!-- LOADER -->
<ng-container>
  <app-loader *ngIf="!dataAvailable"></app-loader>
</ng-container>

<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <div class="horizontal-line"></div>

  <div class="booking-type-selector mt-5">
    <div class="selector-item" (click)="reservationMode = true" [ngClass]="reservationMode ? 'selected' : 'not-selected'">
      <span class="selector-text">{{ "my_bookings.my_reservations" | translate }}</span>
    </div>
    <div class="selector-item" (click)="reservationMode = false" [ngClass]="!reservationMode ? 'selected' : 'not-selected'">
      <span class="selector-text">{{ "my_bookings.my_matchs" | translate }}</span>
    </div>
  </div>
  <ng-container *ngIf="reservationMode; then reservationList; else matchList"></ng-container>
</div>


<ng-template #reservationList>
  <div class="my-reservations mt-3 mb-5">
    <h2 class="sub-title">{{ 'my_bookings.my_reservations' | translate }}</h2>
    <p class="text">{{ 'my_bookings.see_and_cancel_reservations' | translate }}</p>
  </div>
  <ng-container *ngTemplateOutlet="reservationsTemplate"></ng-container>
</ng-template>

<ng-template #matchList>
  <div class="my-reservations mt-3 mb-5">
    <h2 class="sub-title">{{ 'my_bookings.my_matchs' | translate }}</h2>
    <p class="text">{{ 'my_bookings.see_and_cancel_matchs' | translate }}</p>
  </div>
  <ng-container *ngTemplateOutlet="matchsTemplate"></ng-container>
</ng-template>


<ng-template #reservationsTemplate>
  <ng-container *ngTemplateOutlet="noResaToComeOrDone"></ng-container>
  <ng-container *ngTemplateOutlet="resaToComeList"></ng-container>
  <ng-container *ngTemplateOutlet="resaDoneList"></ng-container>
</ng-template>

<ng-template #matchsTemplate>
  <ng-container *ngTemplateOutlet="noMatchToComeOrDone"></ng-container>
  <ng-container *ngTemplateOutlet="matchTemplateList"></ng-container>
</ng-template>

<ng-template #reservationTemplate let-resa>
  <div class="reservation-container">
    <div class="row" routerLink="/reservation/{{resa.id}}">
      <div class="col-md-3 col-12 text-center">
        <svg class="coupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
        </svg>
        <span class="text-bold"> {{ resa.center.name }} </span>
      </div>
      <div class="col-md-3 col-12 text-center"> <span class="text-details">
          {{resa.start | date: "dd/MM/y" }}
        {{ 'my_bookings.at' | translate }}
        {{resa.start | date: "HH:mm" }}
        </span></div>
      <div class="col-md-3 col-12 text-center" >
        <span class="text-details"> {{ resa.activityName | titlecase }} </span>
        <span class="dash"> - </span>
        <span class="text-details"> {{ resa.duration }} min </span>
      </div>
      <div class="col-md-3 col-12 text-center">
        <span class="text-bold">{{ resa.priceCentsTotal  / 100 | currency:resa.currencyCode:'symbol':'1.2-2':'fr' }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
        </svg>
      </div>
    </div>
    <div class="row mr-3">
      <div class="btn-reservation state-reservation">
        <span *ngIf="isStatusValid(resa.status)">{{ 'my_bookings.reservation_confirmed' |
          translate }}</span>
        <span *ngIf="isStatusCancelled(resa.status)">{{ 'my_bookings.reservation_cancelled' |
          translate }}</span>
        <span *ngIf="isStatusAwaiting(resa.status)">{{ 'my_bookings.reservation_waiting' |
          translate }}</span>
        <svg *ngIf="isStatusValid(resa.status)" class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
             width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,255,255,1)" />
        </svg>
        <svg *ngIf="isStatusCancelled(resa.status)" class="denied" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            fill="rgba(255,255,255,1)" />
        </svg>
        <svg *ngIf="isStatusAwaiting(resa.status)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
             height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2v-.645z"
            fill="rgba(236,240,241,1)" />
        </svg>

      </div>
    </div>
    <div class="horizontal-line"></div>
  </div>
</ng-template>


<ng-template #resaToComeList>
  <ng-container *ngFor="let resa of reservationsToCome; trackBy: trackByFn">
    <ng-container *ngTemplateOutlet="reservationTemplate; context: {$implicit: resa}"></ng-container>
  </ng-container>
</ng-template>


<ng-template #noResaToComeOrDone>
  <div *ngIf="reservationsToCome.length === 0 && pastReservations.length === 0" class="row no-resa-container">
    <div class="col-12 col-md-2 image-container px-4">
      <img class="buddy-img" ngSrc="v1677169402/assets/buddy/buddy_basic.svg" width="128" height="127">
      <h5 class="d-block d-md-none">{{'my_bookings.no_futur_booking_title' | translate}}</h5>
    </div>
    <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4">
      <h5 class="d-none d-md-block">{{'my_bookings.no_futur_booking_title' | translate}}</h5>
      <p>{{'my_bookings.no_futur_booking_text' | translate}}</p>
    </div>
    <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
      <button (click)="redirectToResa()" class="col-12 col-md-10">{{'my_bookings.book_sport' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #resaDoneList>
  <ng-container *ngFor="let resa of pastReservations; trackBy: trackByFn">
    <ng-container *ngTemplateOutlet="reservationTemplate; context: {$implicit: resa}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #noMatchToComeOrDone>
  <div *ngIf="matchs.length === 0" class="row no-resa-container">
    <div class="col-12 col-md-2 image-container px-4">
      <img class="buddy-img" ngSrc="v1677169402/assets/buddy/buddy_basic.svg" width="128" height="127">
      <h5 class="d-block d-md-none">{{'my_bookings.no_futur_match_title' | translate}}</h5>
    </div>
    <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4">
      <h5 class="d-none d-md-block">{{'my_bookings.no_futur_match_title' | translate}}</h5>
      <p>{{'my_bookings.no_futur_booking_text' | translate}}</p>
    </div>
    <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
      <button (click)="redirectToResa()" class="col-12 col-md-10">{{'my_bookings.book_sport' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #matchTemplateList>
  <div *ngFor="let match of this.matchs">
    <div class="reservation-container">
      <div class="row" >
        <div class="row mr-3" routerLink="/match/{{match.id}}">
          <div class="col-md-3 col-12 text-center">
            <svg class="coupon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M2 4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5.5a2.5 2.5 0 1 0 0 5V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm6.085 15a1.5 1.5 0 0 1 2.83 0H20v-2.968a4.5 4.5 0 0 1 0-8.064V5h-9.085a1.5 1.5 0 0 1-2.83 0H4v14h4.085zM9.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
            </svg>
            <span class="text-bold"> {{ match.centerService.centerName }} </span>
          </div>
          <div class="col-md-3 col-12 text-center"> <span class="text-details">
            {{ match.dateFMT }}
          </span></div>
          <div class="col-md-2 col-12 text-center"> <span class="text-details">
            {{ match.timeFMT }}
          </span></div>
          <div class="col-md-2 col-12 text-center" >
            <span class="text-details"> {{ match.centerService.activity | titlecase }} </span>
          </div>
          <div class="col-md-2 col-12 text-center">
            <span class="text-bold">{{ match.priceFMT }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </div>
        </div>

        <div class="row mr-3">
          <div class="btn-reservation state-reservation" [class.mb-2]="match.shareLink && match.shareLink.link" *ngIf="match.status">
            <div *ngIf="isStatusMatchValid(match.status)">
              <span>{{ 'my_bookings.match_confirmed' |translate }}</span>
              <svg class="valid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,255,255,1)" />
              </svg>
            </div>

            <div *ngIf="isStatusMatchCancelled(match.status)">
              <span>{{ 'my_bookings.match_cancelled' |translate }}</span>
              <svg class="denied" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,255,255,1)" />
              </svg>
            </div>

            <div *ngIf="isStatusMatchAwaiting(match.status) || isStatusMatchAwaitingValidation(match.status)">
              <span *ngIf="isStatusMatchAwaitingValidation(match.status)">{{ 'my_bookings.match_waiting_validation' |translate }}</span>
              <span *ngIf="isStatusMatchAwaiting(match.status)">{{ 'my_bookings.match_waiting' |translate }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2v-.645z" fill="rgba(236,240,241,1)" />
              </svg>
            </div>

          </div>
        </div>

        <div class="row mt-0" *ngIf="match.shareLink && match.shareLink.link">
          <div class="btn-partage state-reservation"  *ngIf="!this.shareClicking || (match.id != this.actualMatchId)" (click)="toast(match.id)" [cdkCopyToClipboard]="match.shareLink.link">
            <span>{{ 'my_bookings.share_match' |translate }}</span>
          </div>
          <div class="btn-partage2 state-reservation"  *ngIf="this.shareClicking && (match.id == this.actualMatchId)" >
            <span>{{ 'my_bookings.copied_link' |translate }}</span>
          </div>
        </div>

      </div>
      <div class="horizontal-line"></div>
    </div>
  </div>

  <div class="row mt-2 d-block">
    <div class="col-12">
      <button class="btn-partage state-reservation yellow no-border" *ngIf="paginatedMatch.paging.hasNextPage == true && !loadMatchs && !loadMatchsError" (click)="getNetxMatch()">
        <span>{{ 'my_bookings.next' |translate }}</span>
      </button>
    </div>
    <div class="col-12">
      <div class="mt-2 d-flex" *ngIf="loadMatchs">
        <div class="spinner-border spinner m-auto"  role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div *ngIf="loadMatchsError && !loadMatchs">
        <div class="my-2 d-flex">
          <span class="m-auto error">{{loadMatchsError}}</span>
        </div>
        <div>
          <button class="btn-partage state-reservation no-border" (click)="retryGetMatch()">{{ 'my_bookings.retry' |translate }}</button>
        </div>
      </div>
    </div>
  </div>

</ng-template>

