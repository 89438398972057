import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Promotion } from '../../models/promotion';
import { BrowserStorageService } from '../../service/storage.service';
import { VoucherService } from '../../service/voucher.service';

@Component({
  selector: 'app-external-code',
  templateUrl: './external-code.component.html',
  styleUrls: ['./external-code.component.scss'],
})
export class ExternalCodeComponent implements OnInit {
  @Input() modal: NgbActiveModal;
  @Input() imgPartner: string;
  @Input() partner: string;
  @Input() startDate: string;
  @Input() duration: string;
  @Input() price: number;
  @Input() centerId: string;
  @Input() activity: string;
  @Input() isPayOnlyMine: boolean;
  @Output() voucher = new EventEmitter();
  public error = false;
  public errorMsg = '';
  public promotionModel: Promotion;
  public loading = false;

  formsGymlib: FormGroup[] = [];
  maxForms = 4;
  hasReachedMaxForms = false;

  constructor(
    public voucherService: VoucherService,
    private sessionStorageService: BrowserStorageService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.activity == 'padel') {
      if(this.isPayOnlyMine){
        this.maxForms = 1;
      }else{
        this.maxForms = 4;
      }
    } else {
      if(this.isPayOnlyMine){
        this.maxForms = 1;
      }else{
        this.maxForms = 2;
      }
    }
    for (let i = 0; i < this.maxForms; i++) {
      this.formsGymlib.push(
        this.formBuilder.group({
          id: new FormControl('', this.getValidators(i, 'id')),
          mail: new FormControl('', this.getValidators(i, 'mail'))
        })
      );
    }
    console.log()
  }

  getIdForm(index: number): string {
    if (this.formsGymlib[index]) {
      return this.formsGymlib[index].controls.id.value;
    }
    return null;
  }

  getEmailForm(index: number): string {
    if (this.formsGymlib[index]) {
      return this.formsGymlib[index].controls.mail.value;
    }
    return null;
  }

  maxLengthValidator(maxLength: number) {
    return (control: FormControl) => {
      return control.value && control.value.length > maxLength ? { maxLength: true } : null;
    };
  }

  allowedCharactersValidator() {
    return (control: FormControl) => {
      const regex = /^[A-Za-z0-9]+$/;
      return control.value && !regex.test(control.value) ? { allowedCharacters: true } : null;
    };
  }
  duplicateIdValidator(): ValidatorFn {
    return (control: FormControl) => {
      const idValue = control.value;
      // Ignore les champs vides
      if (!idValue) {
        return null;
      }
      const duplicate = this.formsGymlib.some((form) => {
        return form.controls.id.value === idValue && form.controls.id !== control;
      });
      return duplicate ? { duplicateId: true } : null;
    };
  }

  duplicateEmailValidator(): ValidatorFn {
    return (control: FormControl) => {
      const emailValue = control.value;
      // Ignore les champs vides
      if (!emailValue) {
        return null;
      }
      const duplicate = this.formsGymlib.some((form) => {
        return form.controls.mail.value === emailValue && form.controls.mail !== control;
      });
      return duplicate ? { duplicateEmail: true } : null;
    };
  }

  getValidators(index: number, type: 'id' | 'mail'): ValidatorFn[] {
    const baseValidators = [];

    if (type === 'id') {
      baseValidators.push(
        this.maxLengthValidator(8),
        this.allowedCharactersValidator(),
        this.duplicateIdValidator()
      );
    } else {
      baseValidators.push(Validators.email, this.duplicateEmailValidator());
    }

    if (index === 0) {
      baseValidators.push(Validators.required);
    }

    return baseValidators;
  }

  isFieldInvalid(formGroup: FormGroup, fieldName: string): boolean {
    return formGroup.controls[fieldName].touched && formGroup.controls[fieldName].invalid;
  }

  areAllFormsValid(): boolean {
    return this.formsGymlib.every(form => form.valid);
  }

  public onSubmit(): void {
    this.formsGymlib.forEach(form => form.markAsDirty());
    const validForms = this.formsGymlib.map(form => form.valid);
    if (validForms.findIndex(valid => valid == false) == -1) {
      this.loading = true;
      this.voucherService
        .checkVoucher({
          type: this.partner.toLowerCase(),
          code1: this.getIdForm(0),
          email: this.getEmailForm(0),
          code2: this.getIdForm(1),
          email2: this.getEmailForm(1),
          code3: this.getIdForm(2),
          email3: this.getEmailForm(2),
          code4: this.getIdForm(3),
          email4: this.getEmailForm(3),
          activity: this.activity,
          startDate: this.startDate,
          duration: this.duration,
          price: Math.trunc(this.price),
          centerId: this.centerId
        })
        .subscribe({
          next: (code) => {
            this.loading = false;
            this.sessionStorageService.setObject('voucherPromo', {
              information: code.information,
              amount: code.discountAmount,
              operation: code.discountOperation,
            });

            this.promotionModel = new Promotion(
              this.partner.toLowerCase(),
              this.getIdForm(0),
              this.getEmailForm(0),
              this.getIdForm(1),
              this.getEmailForm(1),
              this.getIdForm(2),
              this.getEmailForm(2),
              this.getIdForm(3),
              this.getEmailForm(3),
            );
            this.voucher.emit({ code, promo: this.promotionModel });
          },
          error: (error) => {
            this.loading = false;
            this.error = true;
            this.errorMsg = error.error.message;
          }
        });
    } else {
      console.error(this.formsGymlib[0].controls.mail.errors)
    }
  }
}
