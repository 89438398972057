<ngx-json-ld [json]="jsonld"></ngx-json-ld>
<div class="spinner-border spinner" role="status" *ngIf="loading">
    <span class="sr-only"></span>
</div>

<app-top-navbar
    *ngIf="!isMobileResolution"
    [isMobileResolution]="isMobileResolution"
    [country]="country">
    <div
        class="d-flex justify-content-between align-items-center ms-auto"
        app-top-navbar-right
        *ngIf="!isMobileResolution">
        <a [routerLink]="['/' + country, 'on-boarding']">
            <button class="navbar-btn">
                {{'menuComponent.add_your_center' | translate}}
            </button>
        </a>
        <app-button-account-disconnected></app-button-account-disconnected>
    </div>
</app-top-navbar>

<div *ngIf="dataAvailable">
    <div class="d-flex flex-column " id="top" *ngIf="!displayReviewsPage; else reviewsPage;"
        [ngClass]="{'center-div-mobile': isMobileResolution, 'center-div-desktop mx-auto': !isMobileResolution}">
        <!-- top  -->
        <nav class="navbar navbar-light justify-content-between mt-1">
            <a [routerLink]="['/' + country, translatedPathSportCity, slug + '-' + center.address.postalCode]" style="cursor: pointer;" class="breadcrumb-link grey-text ">
                <button class="custom-img">
                    <img class="icon-nav" ngSrc="v1677166288/assets/arrow/arrow-left-line.png" alt="{{'center.back' | translate}}" width="36" height="36">
                </button>
            </a>
            <p class="center-name-text align-self-center mb-0 mx-2" *ngIf="isMobileResolution">{{ center.name }}</p>


            <button class="custom-link-black custom-img mx-2" (click)="openShareModal()">
                <img class="icon-nav share-icon me-2" ngSrc="v1677166288/assets/icon/share-fill.png"
                    alt="{{'center.share' | translate}}" width="36" height="36">
                <span *ngIf="!isMobileResolution">
                    {{'center.share' | translate}}
                </span>
            </button>
        </nav>

        <div class="d-flex flex-column top-div header-club" [style]="headerBackground"
            [ngClass]="{'header-club-desktop': !isMobileResolution}">
        </div>

        <!-- main  -->
        <div class="d-flex flex-column" *ngIf="dataAvailable">
            <div class="card custom-card" [ngClass]="{'card-desktop': !isMobileResolution}">
                <div class="d-flex flex-column card-body mt-4 mb-4">
                    <div [ngClass]="{'ms-4 me-4': !isMobileResolution,  'mx-4': isMobileResolution}">
                        <div class="no-padding-div">
                            <div class="title-container">
                                <h4 class="card-title mb-1">{{center.name}}</h4>
                                <div class="favorite-container" (click)="setFavorite()">
                                    <svg *ngIf="!favorite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0H24V24H0z"/><path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"/></svg>
                                    <svg *ngIf="favorite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0H24V24H0z"/><path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" fill="rgba(255,97,97,1)"/></svg>
                                </div>
                            </div>
                            <a class="card-subtitle grey-text mb-2" [href]="googleMapLink" rel="noreferrer" target="_blank">{{center.address.line1 | titlecase }}
                                {{center.address.postalCode}} {{center.address.city | titlecase}} </a>
                        </div>
                        <div class="col-2 no-padding-div fav-div" *ngIf="false">
                            <button class="custom-img custom-link-black ">
                                <img class="fav-img me-2" alt="{{'center.fav' | translate}}"
                                    ngSrc="v1677166288/assets/icon/heart-fill.png" *ngIf="fav; else notFav" width="36" height="36">
                                <ng-template #notFav>
                                    <img class="fav-img me-2" alt="{{'center.not_fav' | translate}}"
                                        ngSrc="v1677166288/assets/icon/heart-line.png" width="36" height="36">
                                </ng-template>
                                <span *ngIf="!isMobileResolution">
                                    {{'center.fav' | translate}}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex review-link">
                        <div class="ms-4 " (click)="onDisplayReviews()" *ngIf="!isMobileResolution" >
                            <app-star-rating [rating]="this.center.rating" [ratingCount]="this.center.ratingCount"
                                [long]="true" [linkReview]="true" [underline]="true" [medium]="true" (click)="reloadPageReviews()">
                            </app-star-rating>
                        </div>
                        <div class="ms-4" (click)="onDisplayReviews()" *ngIf="isMobileResolution">
                            <app-star-rating [rating]="this.center.rating" [ratingCount]="this.center.ratingCount"
                                [long]="true" [linkReview]="true" [underline]="true" [medium]="true" (click)="reloadPageReviews()">
                            </app-star-rating>
                        </div>
                    </div>

                    <div class="ms-2">
                        <nav aria-label="breadcrumb custom-breadcrumb">
                            <ol class="breadcrumb breadcrumb-ol grey-text ">
                                <li class="breadcrumb-item">
                                    <a [routerLink]="['/']" class="breadcrumb-link grey-text ">
                                        {{'appComponent.home' | translate}}
                                    </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a [routerLink]="['/' + country, translatedPathSportCity, slug + '-' + center.address.postalCode]" style="cursor: pointer;" class="breadcrumb-link grey-text ">
                                        {{center.address.city}}
                                    </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a [routerLink]="['/' + country, 'club-' + center.id]" class="breadcrumb-link grey-text">
                                        {{center.name}}
                                    </a>
                                </li>
                                <li class="breadcrumb-item" *ngIf="res">
                                    <a [routerLink]="['/' + country, 'club-' + center.id, translatedPathBooking]" class="breadcrumb-link grey-text">
                                        {{'center.booking' | translate}}
                                    </a>
                                </li>
                                <li class="breadcrumb-item active grey-text" *ngIf="sportName" aria-current="page">
                                    {{selectedSport}}
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeNav"
                        [ngClass]="{'mt-5': !isMobileResolution,  'mt-3': isMobileResolution}">

                        <!-- infos club  -->
                        <li [ngbNavItem]="1" class="center-nav-item">
                          <a [routerLink]="['/' + country, 'club-'+ center.id]" ngbNavLink class="center-nav-link" [ngStyle]="{'border-bottom': activeNav===1 ? (' 3px solid #ffc300'):'green'}"><h2 class="second-title">{{'center.info_club' | translate}} </h2></a>
                            <ng-template ngbNavContent>
                              <div>
                                <h1 class="first-title mt-2">
                                  {{ 'center.play_at' | translate : {centerName: center.name, city: center.address.city} }}
                                </h1>
                              </div>
                              <div class="row my-4 reverse" *ngIf="center && center.isPartner">
                                <div class="col-md-5">
                                  <app-sports-carousel [isMobileResolution]="isMobileResolution" [pageClub]="true"
                                                       [activities]="center.activities"
                                                       [sportAvaibility]="selectedSport"
                                                       [url]="urlPath"
                                                       (newSportSelectedEvent)="newSportSelected($event, true)">
                                  </app-sports-carousel>
                                </div>
                                <div class="col-md-7 pt-3">
                                  <div class="d-flex" *ngIf="center.isFullAnybuddy">
                                    <div>
                                      <svg height="36" width="36" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                        <circle fill="#ffc300" cx="150" cy="150" r="101"/><circle fill="#ffc300" cx="198.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="217.29" cy="155.94" r="54.71"/><circle fill="#ffc300" cx="198.29" cy="195.94" r="54.71"/><circle fill="#ffc300" cx="150.29" cy="216.94" r="54.71"/><circle fill="#ffc300" cx="103.29" cy="197.94" r="54.71"/><circle fill="#ffc300" cx="81.29" cy="156.94" r="54.71"/><circle fill="#ffc300" cx="95.29" cy="101.94" r="54.71"/><circle fill="#ffc300" cx="146.29" cy="78.94" r="54.71"/><path fill="#fff" d="M137.61,177.54l62.2-86.26,13.28,9.57-71.78,99.53-59.71-43.07,9.57-13.27,46.45,33.5Z"/>
                                      </svg>
                                    </div>
                                    <div class="ms-4">
                                      <span class="info-title">{{'center.certified' | translate}}</span>
                                      <p class="grey">{{'center_availabilities.isFullAnybuddy' | translate}}</p>
                                    </div>
                                  </div>

                                  <div class="d-flex" *ngIf="center.isFullAnybuddy">
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 11h20v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9zm15-8h4a1 1 0 0 1 1 1v5H2V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2z" fill="rgba(255,195,0,1)"/></svg>
                                    </div>
                                    <div class="ms-4">
                                      <span class="info-title">{{'center.cancelation' | translate}}</span>
                                      <p class="grey mb-0">
                                        {{getPolicyCancellationText(center.cancellationPolicy)}}
                                      </p>
                                      <a style="color: black" href="https://support.anybuddyapp.com/fr/article/comment-fonctionnent-les-frais-dannulation-1ojunmv/">
                                        <p style="margin-bottom: 1rem;text-decoration: underline;cursor: pointer" >{{'center.cancelationMoreInfo' | translate}}
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
                                        </p>
                                      </a>
                                    </div>
                                  </div>

                                  <div class="d-flex" *ngIf="center.bookingRules">
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z" fill="rgba(255,195,0,1)"/></svg>
                                    </div>
                                    <div class="ms-4">
                                      <span class="info-title">{{'center.bookInfo' | translate}}</span>
                                      <p class="grey">{{center.bookingRules}}</p>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <h2 class="mb-2 horizontal-line m-auto" *ngIf="center && center.isPartner"></h2>

                                <div class="d-flex flex-column" *ngIf="dataAvailable && center && center.isPartner">
                                    <div class="d-flex flex-column  mx-4"
                                    [ngClass]="{'my-5': !isMobileResolution,  'mt-4 mb-3': isMobileResolution}">
                                    <h3 class="expansion-header mb-4 card-title">
                                        {{'center.the_club' | translate}}
                                    </h3>
                                    <div class="text mb-3">
                                        <div [innerHtml]="center.longDescription"></div>
                                        <span *ngIf="!center.shortDescription || center.shortDescription === ''">
                                            {{center.no_description | translate }}
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-start mb-3 underline"
                                        *ngIf="isMobileResolution">
                                        <button class="custom-link-black custom-img underline align-self-start"
                                            (click)="displayLongDescription = !displayLongDescription">
                                            <span *ngIf="!displayLongDescription">
                                                {{'center.see_more_info' | translate}}
                                            </span>
                                            <span *ngIf="displayLongDescription">
                                                {{'center.see_more_info' | translate}}
                                            </span>
                                        </button>
                                        <img class="amenity-img custom-img align-self-center mb-0"
                                            alt="{{'center.see_more_info' | translate}}"
                                            ngSrc="v1677166288/assets/arrow/arrow-down.png" *ngIf="!displayLongDescription" width="36" height="36">
                                        <img class="amenity-img custom-img align-self-center mb-0"
                                            alt="{{'center.see_less_info' | translate}}"
                                            ngSrc="v1677166288/assets/arrow/arrow-up.png" *ngIf="displayLongDescription" width="36" height="36">
                                    </div>
                                    <div class="text mb-3" *ngIf="displayLongDescription">
                                        <div [innerHtml]>{{center.shortDescription}}</div>
                                    </div>
                                </div>

                                  <h2 class="mb-2 horizontal-line"></h2>
                                  <div *ngIf="!loading && dataAvailable">
                                    <app-amenities [amenities]="amenities" [isMobileResolution]="isMobileResolution">
                                    </app-amenities>

                                  </div>

                                   <h2 class="mb-2 horizontal-line"></h2>
                                    <button  class="my-2 fab" [routerLink]="['/' + country, 'club-' + center.id, translatedPathBooking]">
                                      {{ 'center.booking' | translate }}
                                    </button>
                                   <h2 class="mb-2 horizontal-line"></h2>

                                    <div *ngIf="!loading && dataAvailable">
                                        <app-localisation [center]="center" [markers]="markers"
                                            [centerCoord]="centerCoord" [googleMapLink]="googleMapLink"
                                            [isMobileResolution]="isMobileResolution"></app-localisation>
                                    </div>

                                  <h2 class="mb-2 horizontal-line"></h2>

                                  <div *ngIf="center !== null && center !== undefined">
                                    <app-activity-details [center]="center"></app-activity-details>
                                  </div>
                                  <h2 class="mb-2 horizontal-line"></h2>

                                  <div *ngIf="center !== null && center !== undefined">
                                    <app-center-recommendations [center]="center" [isMobileResolution]="isMobileResolution"></app-center-recommendations>
                                  </div>

                                </div>
                              <div *ngIf="!loading && dataAvailable">
                                <app-infos-club-no-partner *ngIf="center && !center.isPartner"
                                     [center]="center"
                                     [country]="country">
                                 </app-infos-club-no-partner>
                              </div>
                            </ng-template>

                        </li>


                        <!-- réservation  -->
                        <li [ngbNavItem]="2" [disabled]="!center.isPartner" class="center-nav-item">
                          <a ngbNavLink [routerLink]="['/' + country, 'club-' + center.id, translatedPathBooking]" class="center-nav-link" [ngStyle]="{'border-bottom': activeNav=== 2 ? (' 3px solid #ffc300'):'green'}"><h2 class="second-title">{{'center.booking' | translate}}</h2></a>
                            <ng-template ngbNavContent>
                              <div>
                                <h1 class="first-title mt-2 mb-4">{{metaTitle}}</h1>
                              </div>
                                <!-- <app-info *ngIf="center.address.country=='FR'" [isMobileResolution]="isMobileResolution"></app-info> -->
                                <app-select-activity-form class="select-activity-form" [activities]="center.activities"
                                [slotDate]="dateSlots" [sportAvaibility]="selectedSport" [centerId]="centerId" [center]="center" (newSportSelectedEvent)="newSportSelected($event)">
                                </app-select-activity-form>
                            </ng-template>
                        </li>

                        <!-- reviews  -->
                        <li [ngbNavItem]="3"  class="center-nav-item" *ngIf="!isMobileResolution && center.isPartner"
                            [disabled]="this.center.ratingCount === 0">
                            <div *ngIf="this.center.ratingCount === 0; else review">
                              <a ngbNavLink class="center-nav-link"  [ngStyle]="{'border-bottom': activeNav=== 3 ? ('3px solid #ffc300'):'green'}"><h2 class="second-title">{{'center.no_review' | translate}}</h2></a>
                            </div>
                            <ng-template #review>
                                <a ngbNavLink [routerLink]="['/' + country, 'club-' + center.id, translatedPathReviews]" class="center-nav-link"  [ngStyle]="{'border-bottom': activeNav=== 3 ? ('3px solid #ffc300'):'green'}">{{'center.reviews' | translate}}</a>
                            </ng-template>
                            <ng-template ngbNavContent>
                                <app-reviews (closeReviewPage)="onCloseReviewPage($event)" [center]="center"></app-reviews>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>

                <h2 class="mb-2 horizontal-line" *ngIf="!isEspangole"></h2>

                <app-assistance  [isMobileResolution]="isMobileResolution" *ngIf="!isEspangole"></app-assistance>

                <h2 class="mb-2 horizontal-line"></h2>

                <div class="d-flex flex-column mt-5" *ngIf="isMobileResolution">
                    <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
                </div>
            </div>
        </div>
    </div>

    <ng-template #reviewsPage>
        <app-reviews (closeReviewPage)="onCloseReviewPage($event)" [center]="center" [centerName]="centerName"></app-reviews>
    </ng-template>
    <div class="modal-share" *ngIf="isShareOpen" >
        <div class="modal-share__bg" (click)="closeShareModal()" *ngIf="isShareOpen"></div>

        <div class="modal-share__container">
          <div class="modal-share__close modal-close" (click)="closeShareModal()">
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
              <g fill="#979BAA" fill-rule="evenodd">
                <path d="M-.3137085 2.51471863L2.51471863-.3137085 22.3137085 19.48528137l-2.82842713 2.82842713z" />
                <path d="M19.48528137-.3137085l2.82842713 2.82842713L2.51471863 22.3137085-.3137085 19.48528137z" />
              </g>
            </svg>
          </div>

          <div class="socials-wrapper">
            <h3>{{ 'detail.share' | translate }}</h3>

            <div class="social-btns">
              <div class="social-btn fb" (click)="fbShare()">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.3246158 24C.5928363 24 0 23.406796 0 22.675301V1.324605C0 .592925.5929295 0 1.3246158 0H22.675477C23.406977 0 24 .592925 24 1.324605v21.350696C24 23.406889 23.406884 24 22.675477 24h-6.115824v-9.294123h3.119608l.467117-3.622084h-3.586725V8.771321c0-1.048677.291203-1.763316 1.794992-1.763316l1.918011-.000838V3.767545c-.331713-.044141-1.470264-.14276-2.79488-.14276-2.765359 0-4.658598 1.687978-4.658598 4.787819v2.671189H9.691561v3.622084h3.127617V24H1.3246158z"
                    fill="#FFF" fill-rule="evenodd" />
                </svg>
              </div>
              <div class="social-btn twitter" (click)="twitterShare()">
                <svg width="24" height="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.172742 3.162152c1.01708-.624052 1.797499-1.613579 2.16517-2.792343-.952281.577826-2.004579.998194-3.12872 1.223546C19.313259.612495 18.032752 0 16.615601 0c-2.717379 0-4.921993 2.260744-4.921993 5.048754 0 .395811.042261.780065.126783 1.149874C7.728121 5.987721 4.100722 3.979776 1.670717.921632c-.424019.748285-.666315 1.616468-.666315 2.54099 0 1.750812.869167 3.296497 2.190527 4.202239-.805776-.024558-1.566473-.254243-2.231379-.62983v.062116c0 2.447093 1.697482 4.488263 3.9514 4.950524-.412749.118454-.848037.177681-1.297412.177681-.316957 0-.626871-.030336-.926923-.089563.62687 2.005056 2.444092 3.465511 4.599401 3.504514-1.684804 1.355002-3.809121 2.162514-6.115161 2.162514-.397253 0-.79028-.023113-1.174855-.069339C2.179257 19.163597 4.768445 20 7.547808 20c9.057932 0 14.009508-7.692308 14.009508-14.364753 0-.219573-.004226-.439147-.012678-.654387.962141-.712171 1.7975-1.600578 2.455362-2.613218-.881845.401589-1.831308.673167-2.827258.79451z"
                    fill="#FFF" fill-rule="evenodd" />
                </svg>
              </div>
              <div class="social-btn messenger" (click)="messengerShare()">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 0C5.372571 0 0 4.974593 0 11.111111c0 3.496667 1.744994 6.61563 4.47205 8.65237V24l4.085888-2.242444c1.090435.301777 2.24564.464666 3.442062.464666 6.627429 0 12-4.974592 12-11.111111C24 4.974593 18.627429 0 12 0zm1.192547 14.962963l-3.055901-3.259259-5.962733 3.259259L10.732919 8l3.130435 3.259259L19.751553 8l-6.559006 6.962963z"
                    fill="#FFF" fill-rule="evenodd" />
                </svg>
              </div>
              <div class="social-btn email">
                <a href="mailto:?subject='Découvre Anybuddy !'">
                    <svg width="24" height="24"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                </a>
              </div>
            </div>
          </div>
          <button class="buttonError" (click)="openModal(template)">{{ 'instructions.message' | translate }}</button>
        </div>
      </div>
    <div class="d-flex flex-column mt-2 mb-3 mx-5 px-5" *ngIf="!isMobileResolution">
        <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
    </div>
</div>
<div class="modal-share" *ngIf="isModalOpen">
  <div class="modal-share__bg" (click)="closeModal()"></div>

  <div class="modal-share__container">
    <div class="modal-share__close modal-close" (click)="closeModal()">
      <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fill="#979BAA" fill-rule="evenodd">
          <path d="M-.3137085 2.51471863L2.51471863-.3137085 22.3137085 19.48528137l-2.82842713 2.82842713z" />
          <path d="M19.48528137-.3137085l2.82842713 2.82842713L2.51471863 22.3137085-.3137085 19.48528137z" />
        </g>
      </svg>
    </div>

    <div class="socials-wrapper2">
      <h3 class="socials-wrapper2">{{ 'instructions.title' | translate }}</h3>
      <div class="modal-body">
        <p>{{ 'instructions.body' | translate }}</p>
        <ol>
          <li *ngFor="let step of 'instructions.steps' | translate">{{ step }}</li>
        </ol>
        <details *ngFor="let detail of 'instructions.details'| translate">
          <summary>{{ detail.os}}</summary>
          <ul>
            <li *ngFor="let instruction of detail.instructions">{{ instruction }}</li>
          </ul>
        </details>
      </div>
    </div>
  </div>
</div>
