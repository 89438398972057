import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Reservation } from '../models/reservation';
import { PaginatedMatch} from '../models/paginated-match';
import { ReservationsService } from '../service/reservations.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { Router } from "@angular/router";
import { AppContextService } from '../../shared/services/app-context.service';
import {MatchData} from "../models/matchData";
import {MatchService} from "../service/match.service";

enum StatusResa {
  Fail = 'failed',
  Cancel = 'cancelled',
  Confirm = 'confirmed',
  Awaiting = 'awaitingValidation',
}

enum StatusMatch {
  Init = 'init',
  AwaitingPlayers = 'awaitingPayment',
  AwaitingValidation = 'awaitingValidation',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled'
}

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent implements OnInit {
  @Input() isMobileResolution: boolean;
  reservationMode = true;

  currentDate: Date = new Date();
  dataAvailable = false;
  reservationsToCome: Reservation[] = [];
  pastReservations: Reservation[] = [];
  isWhiteLabel: boolean;
  shareClicking : boolean = false;
  actualMatchId : string = "";
  currentPageMatch : number = 1;

  loadMatchs = false;
  loadMatchsError = null;

  public status = StatusResa;
  public statusMatch = StatusMatch
  public lang: string;
  public paginatedMatch: PaginatedMatch;
  public matchs: MatchData[] = [];

  constructor(
    private reservationsService: ReservationsService,
    private matchService: MatchService,
    private userPreferenceService: UserPreferenceService,
    private router: Router,
    private appContextService: AppContextService
  ) {
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
  }

  ngOnInit(): void {
    this.lang = this.userPreferenceService.getCountry();
    const reservations$ = this.getReservation();
    reservations$.subscribe({
      next: (res) => {
        this.filterData(res);
        this.dataAvailable = true;
        console.log(this.pastReservations)
        console.log(this.reservationsToCome)
      },
      error: (error) => {
        console.error(error);
        this.dataAvailable = true;
      }
    });
    this.getMatchsInit();
  }

  getMatchsInit(): void {
    this.loadMatchs = true;
    this.matchService.getMatchs("me", 10).subscribe({
      next : (res ) => {
        this.paginatedMatch = PaginatedMatch.fromJson(res);
        this.matchs = this.paginatedMatch.data;
        this.loadMatchs = false;
      }, error :(error) => {
        console.log(error);
        this.loadMatchsError = error;
        this.loadMatchs = false;
      }
    })
  }

  toast(matchId: string) {
    this.shareClicking = true;
    this.actualMatchId = matchId;
    setTimeout(() => {
      this.shareClicking = false;
      this.actualMatchId = "";
    }, 5000);
  }

  private getReservation(): Observable<any> {
    return this.reservationsService.getReservations();
  }

  private filterData(listReservations): void {
    for (const resa of listReservations) {
      if (this.compareDate(resa.start, this.currentDate) === -1 && !(this.isStatusAwaiting(resa.status) || this.isStatusValid(resa.status))) {
        this.pastReservations.push(resa);
      } else {
        this.reservationsToCome.push(resa);
      }
    }
    this.pastReservations.sort((resA, resB) => resA.start < resB.start ? 1 : -1);
    this.reservationsToCome.sort((resA, resB) => resA.start < resB.start ? 1 : -1);
  }


  // ------------------------------------------------
  // Utilitaires
  // ------------------------------------------------
  isMarket = false;

  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  compareDate(date1: Date, date2: Date): number {
    const d1 = new Date(date1); const d2 = new Date(date2);


    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) { return 0; }

    // Check if the first is greater than second
    if (d1 > d2) { return 1; }

    // Check if the first is less than second
    if (d1 < d2) { return -1; }
  }


  redirectToResa() {
    if (this.isWhiteLabel) {
      this.router.navigate(['/reservation'])
    }
    else if (this.isMarket) {
      this.router.navigate(['/reservation-sport'])
    } else {
      this.router.navigate(['/'])
    }
  }


  isStatusMatchValid(status: string): boolean {
    if (status == this.statusMatch.Confirmed) {
      return true;
    }
    return false;
  }

  isStatusMatchCancelled(status: string): boolean {
    if (status == this.statusMatch.Cancelled || status == this.statusMatch.Init) {
      return true;
    }
    return false;
  }

  isStatusMatchAwaiting(status: string): boolean {
    if (status == this.statusMatch.AwaitingPlayers) {
      return true;
    }
    return false;
  }

  isStatusMatchAwaitingValidation(status: string): boolean {
    if (status == this.statusMatch.AwaitingValidation) {
      return true;
    }
    return false;
  }

  public isStatusValid(status: string): boolean {
    if (status == this.status.Confirm) {
      return true;
    }
    return false;
  }

  isStatusCancelled(status: string): boolean {
    if (status == this.status.Cancel || status == this.status.Fail) {
      return true;
    }
    return false;
  }

  isStatusAwaiting(status: string): boolean {
    if (status == this.status.Awaiting) {
      return true;
    }
    return false;
  }

  retryGetMatch() {
    if(this.paginatedMatch){
      this.getNetxMatch();
    } else {
      this.getMatchsInit();
    }
  }
  getNetxMatch() {
    this.loadMatchs= true;
    this.loadMatchsError = null;
    this.matchService.getMatchByUri(this.paginatedMatch.paging.next).subscribe({
      next: (res) => {
        this.paginatedMatch = PaginatedMatch.fromJson(res);
        this.currentPageMatch = this.currentPageMatch + 1;
        this.matchs = [ ...this.matchs, ...this.paginatedMatch.data]
        this.loadMatchs= false;
      }, error: (error) => {
        console.log(error);
        this.loadMatchsError = error.message;
        this.loadMatchs= false;
      }
    })
  }
}
