import { Component, Injectable, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Center } from '../models/center';
import { CenterFacilities } from '../models/center-facilities';
import { Places } from '../models/places';
import { Reservation } from '../models/reservation';
import { Vouchers } from '../models/vouchers';
import { CentersService } from '../service/center.service';
import { ReservationsService } from '../service/reservations.service';
import { TagManagerService } from '../service/tag-manager.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { AppContextService } from '../../shared/services/app-context.service';
import { repeat, skipWhile, take } from 'rxjs';
import { InfoPaiement } from '../models/infoPaiment';
import { UserService } from '../service/user.service';
import {MatchService} from "../service/match.service";
import {MatchData, MatchPlayer} from "../models/matchData";

enum Status {
  Init = 'init',
  AwaitingPlayers = 'awaitingPayment',
  AwaitingValidation = 'awaitingValidation',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled'
}

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
@Injectable({
  providedIn: 'root'
})

export class MatchComponent implements OnInit {
  reservationId: string;
  centerId: string;
  reservation: Reservation;
  facilities: CenterFacilities[];
  facility: CenterFacilities;
  center: Center;
  vouchers: Vouchers[];
  places: Places[];
  price: any;
  googleMapLink: string;
  isLittleWidth = false;
  dataAvailable = false;
  hideTitle = true;
  isCancel = false;
  notCancelableError: string;
  isError = false;
  isWhiteLabel: boolean;
  @ViewChild('errorInvoice') errorInvoice: TemplateRef<any>;
  public status = Status;
  invoiceErrorMsg: string;
  resaSuccess = false;

  //Nouvelle variables
  configMatch = "";
  matchId = "";
  matchIdCompleteUrl = "";
  link = "";
  nextAction;
  hasJoin = true;
  shareClicking = false;
  defaultPicture = "https://res.cloudinary.com/anybuddy/image/upload/v1677169467/assets/buddy/logo-simple.png";
  match: MatchData;
  playersTeamA: MatchPlayer[] = [];
  playersTeamB: MatchPlayer[] = [];
  matchTotalPrice: string;
  status_match = "";
  matchPublicId: string;
  token: string;
  referralUserCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private reservationsService: ReservationsService,
    private centersService: CentersService,
    private tagManager: TagManagerService,
    private authFirebaseService: AuthFirebaseService,
    private appContextService: AppContextService,
    private userService: UserService,
    private matchService : MatchService,
    private translate: TranslateService,
  ) {
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
  }

  ngOnInit(): void {
    if (window.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.matchIdCompleteUrl = id;

      const parts = this.matchIdCompleteUrl.split('?');
      this.matchPublicId = parts[0];
      if(parts.length > 1){
        const queryParams = this.parseQueryParams(parts[1]);
        this.token = queryParams['token'];
        this.referralUserCode = queryParams['referralUserCode'];
      }
      if (this.matchPublicId) {
        this.getMatchData();
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  private parseQueryParams(queryParamsString: string): { [key: string]: string } {
    const queryParams = {};
    if (queryParamsString) {
      const pairs = queryParamsString.split('&');
      for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[key] = value;
      }
    }
    return queryParams;
  }

  private getMatchData(){
    this.matchService.getMatch(this.matchPublicId, this.token, this.referralUserCode).subscribe(
      (response) => {
        this.match = response;
        this.centerId = this.match.centerService.centerId;
        this.matchId = this.match.id;
        this.playersTeamA = this.match.teamA ? this.match.players.filter(player => this.match.teamA.includes(player.id)) : [];
        this.playersTeamB = this.match.teamB ? this.match.players.filter(player => this.match.teamB.includes(player.id)) : [];
        this.nextAction = this.match.nextAction;
        this.configMatch = this.getMatchConfig(this.match.nbPlayers);
        this.matchTotalPrice = this.match.priceFMT;
        this.link = this.match.shareLink? this.match.shareLink.link : "";
        this.centersService.getCenter(this.centerId).subscribe((res : Center) => {
          this.center = res;
          this.createGoogleMapLink();
        });
        this.status_match = this.match.status;

        this.userService.getMe().subscribe({
          next: (user) => {
            const playerFound = this.match.players.find(player => player.id === user.id);
            if (playerFound) {
              this.hasJoin = true;
              this.reservationId = playerFound.reservationId;
              if (this.reservationId != null) {
                this.loadReservationUntilGoodStatus();
              } else{
                this.dataAvailable = true;
                this.resaSuccess = true;
                console.log("Pas de réservation")
              }
            } else {
              this.hasJoin = false;
              this.resaSuccess = true;
              this.dataAvailable = true;
            }
          },
          error: (error) => {
            // Gérez les erreurs ici
            console.error("Erreur : ", error);
          }
        });

      },error => {
        console.log(error)
      });
  }

  private getMatchConfig(maxPlayers: number): string {
    switch (maxPlayers) {
      case 2:
        return this.translate.instant('match.simple');
      case 4:
        return this.translate.instant('match.double');
      default:
        return this.translate.instant('match.match');
    }
  }

  private loadReservationUntilGoodStatus(): void {
    this.reservationsService
      .getReservation(this.reservationId)
      .pipe(
        repeat({ delay: 1000 }),
        skipWhile(
          (response) => response.status === 'awaitingPaymentCapturable'
        ),
        take(1)
      )
      .subscribe({
        next: (response) => {
          this.loadReservation(response);
        },
        error: (error) => {
          // Gérez les erreurs ici
          console.error("Erreur lors de l'appel du service : ", error);
        }
      });
  }

  joinMatch(){
    this.matchService.joinMatch(this.matchIdCompleteUrl).subscribe({
      next: (response) => {
        this.router.navigate([`/match/${response.id}`]);
      }, error: (error) => {
        // Gérez les erreurs ici
        console.error("Erreur: ", error);
      }
    });
  }

  openLeaveMatchModal(leaveMatch) {
    this.modalService.dismissAll();
    this.modalService.open(leaveMatch, { centered: true, size: 'lg' });

  }

  toast() {
    this.shareClicking = true;
    setTimeout(() => {
      this.shareClicking = false;
    }, 5000);
  }

  getLink(){
    return this.link;
  }

  private loadReservation(reservation: Reservation) {
    if (reservation.status === 'confirmed' || reservation.status === 'awaitingValidation') {
      this.resaSuccess = true;
    } else {
      this.resaSuccess = false;
    }
    this.reservation = reservation;
    this.vouchers = reservation.vouchers;
    this.places = reservation.places;
    this.center = reservation.center;
    console.log(this.reservation)
    this.price = reservation.priceCentsTotal;
    //const nonWalletVoucher = this.vouchers.filter((v) => v.type != 'wallet');
    //nonWalletVoucher.map((v) => (this.price += v.discountAmount));
    this.centersService
      .listCenterFacilities(this.center.id)
      .subscribe((facilities) => {
        this.facilities = facilities;
        this.facilities.forEach((facility) => {
          if (facility.id === this.reservation.facilityId) {
            this.facility = facility;
            this.dataAvailable = true;
          }
        });
      });
    this.tagManager.pushReservationDetails({
      user_id: this.authFirebaseService.getUserDataId(),
      reservation_id: this.reservation.id,
      center_id: this.reservation.center.id,
      activity_id: this.reservation.activityName
    });
  }

  public openFeeInfoModal(feeInfo): void {
    if (this.reservation.serviceFeeText && this.reservation.serviceFeeText !== '') {
      this.modalService.dismissAll();
      this.modalService.open(feeInfo, { centered: true });
    }
  }

  widthSize(event): void {
    if (event.target.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }
  }

  createGoogleMapLink(): void {
    this.googleMapLink = `https://www.google.com/maps/search/?api=1&query=${this.center.location.lat},${this.center.location.lon}`;
  }

  leaveMatch(modal: NgbActiveModal){
    this.isError = false;
    if (this.match.status === this.status.Cancelled) {
      this.notCancelableError = this.translate.instant('my_resa.already_cancelled');
      this.isError = true;
    } else {
      this.matchService.leaveMatch(this.matchId).subscribe({
        next: (response : any) => {
          this.isCancel = true;
          setTimeout(() => {
            modal.close();
            if(response && response.id){
              this.router.navigate([`/match/${response.id}`]);
            }else{
              this.router.navigate([`/`]);
            }
          }, 2000);
        },
        error: (error) => {
          // Gérez les erreurs ici
          this.isError = true;
          this.notCancelableError = error.error.message;
          console.error("Erreur: ", error);
        }
      });
    }
  }

  downloadInvoice(): void {
    this.reservationsService.getInvoice(this.reservation.id).subscribe({
      next: (response: any) => {
        const url = response['invoiceLink'];
        const link = document.createElement('a');
        link.href = url;
        link.download = 'facture';
        link.click();
      },
      error: (error) => {
        this.invoiceErrorMsg = error.message;
        this.modalService.open(this.errorInvoice, { centered: true });
        console.error('Erreur:\n' + error.message);
      }
    });
  }

  canShowDownloadInvoice(): boolean {
    const reservationDate = new Date(this.reservation.start);
    const currentDate = new Date();
    const differenceInDays = Math.ceil(
      (currentDate.getTime() - reservationDate.getTime()) /
      (1000 * 60 * 60 * 24)
    );
    return (
      this.reservation.status === Status.Confirmed && differenceInDays >= 8
    );
  }


  payeTaPlace(){
    sessionStorage.setItem('activities', this.match.centerService.activity);
    const infoPaiement = new InfoPaiement();
    infoPaiement.centerId = this.center.id;
    infoPaiement.serviceId = this.match.centerService.serviceId;
    // infoPaiement.slotId = "MjAyMy0xMi0wNFQxODowMF82MA==";
    infoPaiement.price = this.price;
    infoPaiement.places = [];
    const matchIdToken = this.token ? this.token : this.matchId;
    localStorage.setItem('join', JSON.stringify({'join' : true, 'matchId': matchIdToken}));
    console.log(infoPaiement)
    const serviceId = [{
      duration: 60,
      startDateTime: new Date(),
    }];
    const selectedService = {
      startDateTime: new Date(),
      services: [...serviceId]
    };
    sessionStorage.removeItem('slots');
    sessionStorage.removeItem('infoPaiement');
    sessionStorage.setItem('slots', JSON.stringify(selectedService));
    sessionStorage.setItem('infoPaiement',JSON.stringify(infoPaiement));
    this.router.navigate([`/checkout/${infoPaiement.centerId}/service/${infoPaiement.serviceId}`], {
      state: infoPaiement
    });
  }

}
