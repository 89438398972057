import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter, Inject, Input,
  OnChanges, OnInit, Output,
  PLATFORM_ID, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Candidates } from '../models/candidates';
import { SearchService } from '../service/search.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { environment } from "../../../environments/environment";
import { TagManagerService } from "../service/tag-manager.service";

/* eslint-disable */
export enum TypeGround {
  Terrain = 'terrain',
  Court = 'court',
  Session = "session"
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {

  @Input() querySearch;
  @Input() querySearchfilter;
  @Input() addressSearch;
  @Input() avaibility;
  @Input() isMobileResolution;
  @Output() resolution: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtreQuery: EventEmitter<string> = new EventEmitter<string>();
  @Output() avaibiltyCity: EventEmitter<string> = new EventEmitter<string>();
  @Output() avaibiltyCityDismiss: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchImgSrc = '/assets/images/send-plane-grey.png';
  selectedFilter = '';
  selectedLine = '';
  focusVaribale = false;
  candidates: Candidates[] = [];
  cityArray: Candidates[] = [];
  club: Candidates[] = [];
  coach: Candidates[] = [];
  sport: Candidates[] = [];
  isBrowser: boolean;
  existe = true;
  placeholder = ''
  localUrl = environment.host
  displaySearchPage = false;
  public isResults: boolean;
  public isLoadingSearch = false;
  public country: string = "";

  private typeGround = TypeGround;

  constructor(
    private sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId,
    private searchService: SearchService,
    private router: Router,
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private userPreferenceService: UserPreferenceService,
    private tagManager: TagManagerService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);

  }

  ngOnInit(): void {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== "fr") {
      this.country = this.userPreferenceService.getCountry()
    }
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
    if (this.addressSearch === undefined) {
      this.addressSearch = "paris-75000";
    }
    if (this.avaibility !== undefined) {
      this.existe = false;
      this.placeholder = this.translate.instant('search.city');
    }
    if (this.avaibility === undefined) {
      this.placeholder = this.translate.instant('search.placeholder_form');
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.querySearch !== undefined) {
      this.searchQuery(changes.querySearch.currentValue);
    }
    if (changes.addresSearch !== undefined) {
      this.addressSearch = changes.addresSearch.currentValue;
    }
    if (changes.addresSearch === undefined) {
      if (this.userPreferenceService.getCountry() === 'es') {
        this.addressSearch = 'huelva-21001'
      } else {
        this.addressSearch = "paris-75000";
      }
    }
  }

  onSelectFilter(filter): void {
    if (this.selectedFilter === filter) {
      this.selectedFilter = '';
    } else {
      this.selectedFilter = filter;
    }
    this.filtreQuery.emit(filter);
  }

  searchQuery(serachQuery: string): void {
    this.isLoadingSearch = true;
    this.searchService.searchQuery(serachQuery).subscribe(res => {
      this.initArray();
      this.candidates = res.candidates;
      for (const candidate of res.candidates) {
        if (this.existe == true) {
          if (candidate.type === 'city') {
            this.cityArray.push(candidate);
          }
          if (candidate.type === 'sport_club') {
            this.club.push(candidate);
          }
          if (candidate.type === 'Staff') {
            this.coach.push(candidate);
          }
          if (candidate.type === 'activity') {
            this.sport.push(candidate);
          }
        }
        else {
          if (candidate.type === 'city') {
            this.cityArray.push(candidate);
          }
        }
      }
      this.isResults = this.checkIfResults();
      this.isLoadingSearch = false;
    });
  }

  searchResultCity(id, cityName) {
    if (this.existe === true) {
      const fullCity = id.split('-');
      fullCity.pop();
      const city = fullCity.join('-')
      let translatedPath: any = this.localize.translateRoute(`reservation-sport`);
      this.tagManager.pushSearchCity({
        city: cityName,
      })
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`/${this.userPreferenceService.getCountry()}/${translatedPath}/${city}`])
      } else {
        this.router.navigate([`/${translatedPath}/${city}`])

      }
      //this.router.navigate([translatedPath+'/' + city]);
    }
    else {
      this.avaibiltyCity.emit(id)
    }
  }

  searchResultClub(id: string): void {
    let url: string;
    if (this.userPreferenceService.getCountry() !== 'fr') {
      url = `/${this.userPreferenceService.getCountry()}/${id}`
    } else {
      url = `/${id}`
    }
    this.router.navigate([url])
  }

  searchResult(candidate: Candidates): void {
    if (this.addressSearch !== undefined && this.addressSearch !== null && this.addressSearch != "") {
      const ground: TypeGround = this.getGroundType(candidate.slug)
      let translatedPath: any = this.localize.translateRoute(`reservation-${ground}-${candidate.slug}`);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPath}/${this.addressSearch}`])
      } else {
        this.router.navigate([`/${translatedPath}/${this.addressSearch}`])
      }
    }
    else {
      const pos = candidate.location.lat + "," + candidate.location.lon
      this.searchService.getGeoname(pos).subscribe(res => {
        this.router.navigate(['reservation-' + candidate.slug + '/' + res['slug']]);
      })
    }
  }

  getGroundType(slug: string): TypeGround {
    switch (slug) {
      case 'tennis':
        return this.typeGround.Court;
      case 'fitness':
        return this.typeGround.Session;
      default:
        return this.typeGround.Terrain
    }
  }

  searchFilters(searchQuery: string): void {
    if (searchQuery.length > 2) {
      this.focusVaribale = true;
      this.searchQuery(searchQuery);
    }
    if (searchQuery.length > 0) {
      this.focusVaribale = true;
    }
  }
  initArray(): void {
    this.cityArray = [];
    this.club = [];
    this.coach = [];
    this.sport = [];
  }
  getHome(): void {
    if (this.existe == true) {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    else {
      this.avaibiltyCityDismiss.emit(true)
    }
  }

  private checkIfResults(): boolean {
    if (this.cityArray.length > 0 ||
      this.club.length > 0 ||
      this.sport.length > 0) {
      return true;
    }
    return false;
  }

}

