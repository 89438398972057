<section class="hero">
    <div class="hero-content">
      <h1> {{
        'white_label_home.title' | translate
        }}</h1>
      <p> {{
        'white_label_home.description' | translate
        }}
      </p>
    </div>
  </section>
  <h2 class="centers-title"> {{
    'white_label_home.titre_Second' | translate
    }}</h2>
    
    
    <main class="page-content container-lg">
      <div class="card-deck d-flex justify-content-center align-items-center">
        <ngb-card *ngFor="let center of centers; let i = index" class="card" [style.background-image]="'url(' + center.headerPhoto['1120x560'] + ')'">
          <a [routerLink]="['club', center.id, 'reservation']" class="card-link">
            <!-- En-tête de la carte -->
            <ngb-card-header>
              <div class="card-header">
                <h2 class="title">{{ center.name }}</h2>
              </div>
            </ngb-card-header>
    
            <!-- Contenu du corps de la carte -->
            <ngb-card-body>
              <!-- Contenu du corps de la carte -->
            </ngb-card-body>
    
            <!-- Pied de page de la carte -->
            <ngb-card-footer class="align-self-end">
              <div class="card-footer d-flex flex-column justify-content-between align-items-end">
                <div></div> <!-- Espace vide pour pousser le bouton vers le bas -->
                <button class="btn">
                  {{ 'white_label_home.button' | translate }}
                </button>
              </div>
            </ngb-card-footer>
          </a>
        </ngb-card>
      </div>
    </main>
     