import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPreferenceService } from '../../marketplace/service/user-preferences.service';
import { RouterService } from '../service/router.service';
import { AuthFirebaseService } from 'src/app/marketplace/service/auth-firebase.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() country: string;
  @Input() isTopBarFixed: string;
  @Input() idSelector1Langage = 'dropdownCountry';
  @Input() idSelector2Langage = 'dropdownLangage';
  @Input() isMobileResolution: boolean;
  public sidebarShow = false;
  languageChoosed;
  centerLogo: string;
  public languageFooter: string;
  constructor(
    private userPreferenceService: UserPreferenceService,
    private ngbModale: NgbModal,
    private routerService: RouterService,
    private firebaseAuth: AuthFirebaseService
  ) {
    this.languageFooter = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
    //this.centerLogo = this.whiteLabelCenterService.getWhiteLabelCenterLogo();
    this.centerLogo =
      'https://res.cloudinary.com/anybuddystage/image/upload/f_auto,q_auto,w_200/f_auto,q_auto:eco/v1686034728/logo-forrest-hillpng-CHE2_nn9kpc.jpg';
    this.languageFooter = this.userPreferenceService.getCountry();
    this.userPreferenceService.getChoosedLanguage().subscribe((l) => {
      this.languageChoosed = l;
    });
  }

  openLangageModal(modal: TemplateRef<any>): void {
    this.ngbModale.open(modal, { windowClass: 'custom-modal' });
    this.sidebarShow = !this.sidebarShow;
  }

  isLoginPages(): boolean {
    return this.routerService.getUrl() === '/login';
  }

  toggleMenu() {
    this.sidebarShow = !this.sidebarShow;
  }

  isLoggedIn() {
    return this.firebaseAuth.getIsLoggedIn;
  }
}
