<ng-container *ngIf="loading ; else content">
  <div class="w-100 d-flex">
    <div class="spinner-border  spinner m-auto" role="status" [ngStyle]="{'color':'#ffc300'}" *ngIf="loading">
      <span class="sr-only"></span>
    </div>
  </div>
</ng-container>

<ng-template #content >
  <ng-container *ngIf="servicesSlots.length === 0; then noFacilities else displayFacilities"></ng-container>
</ng-template>


<ng-template #noFacilities>
  <div *ngIf="center && center.bookingRules" >
    {{center.bookingRules}}
  </div>
</ng-template>

<ng-template #displayFacilities>
  <div class="form-check form-switch my-4 mx-2" *ngIf="!isWhiteLabel">
    <input class="form-check-input" type="checkbox" id="viewSwitch"  (change)="onViewSwitch($event)" checked>
    <label class="form-check-label mx-2" for="viewSwitch"> {{'simplified_view.simplified_view_message' | translate}}</label>
  </div>
  <div *ngFor="let session of serviceInformationToShow; let i = index" >
    <div *ngIf="isOtherDay(session)" class="row py-3">
      <div class="col-4 m-auto border-line"></div>
      <div class="px-3 col-4 text-center grey-color">
        {{ isToday(session.startTime) ? ( 'select_activity.today' | translate) : (session.startTime| date:'dd MMM yyyy') }}
      </div>
      <div class="col-4 m-auto border-line"></div>
    </div>

    <div class="card card-container">
      <div class="card-main">
        <div class="card-img-container">
          <img class="card-img card-img-top" [src]="session.photo"  alt="{{getDisplayName(session)}}">
        </div>
        <div class="card-body session">
          <div class="body-container">
            <div class="d-flex justify-content-between card-body-main">
              <div class="card-body-container d-flex flex-column">
                <div class="title-card-container">
                  <a class="title-card-sub-container">
                    <h3 class="title-session-card">{{getDisplayName(session) }} </h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body-session-footer">
              <div class="card-body-session-description">
                <p class="time">
                  {{session.startTime| date:'dd MMM yyyy - HH:mm' }} -
                  {{session.duration }}min
                </p>
                {{session.startDateTime}}
                <!--
                  ---------- Règle multi-prix ----------
                  Si la variable prices est supérieure à 0 et que la variable availablePlaces l'est aussi, alors affiche "voir les tarifs"
                  et redirige l'user sur la page de multi-prix
                  ---------- Cas d'utilisation (pourquoi cette logique ?) ----------
                  Si il n'y a qu'un tarif dans la variable prices mais 2 places dispos alors on redirige vers multi-prix
                  Pareil dans le cas inverse, 2 tarifs mais qu'une place.
                  ---------- Solution pour le back ----------
                  Si il n'y a qu'une place dispo et qu'un price alors ne pas renvoyer la variable prices.
                -->
                <div *ngIf="session.availablePlaces && !isWhiteLabel">
                  {{session.availablePlaces}} {{ 'center_availabilities.places_available' | translate }}
                </div>

                <div class="carac d-flex">
                  <ng-container *ngFor="let characteristics of session.charachteristic;let position=index" >
                    <p>{{ characteristics.name }}</p><span *ngIf="session.charachteristic.length - 1 > position">&nbsp;|&nbsp;</span>
                  </ng-container>
                </div>

              </div>
              <div *ngIf="session.description && session.description !== ''">
                <button (click)="openSessionDescriptionModal(session)" class="btn-session-description">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" fill="rgba(255,255,255,1)"/></svg>
                  <p>{{ 'center_availabilities.know_more' | translate }}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(255,255,255,1)"/></svg>
                </button>
              </div>
            </div>
          </div>
          <div class="tarif">

            <span class="price-title mb-1 font-weight-bolder" *ngIf="selectedSport==='golf' || selectedSport==='fitness'"> {{'select_activity.starting_from' | translate }} {{ session.price / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }}</span>
            <span class="price-title mb-1 font-weight-bolder" *ngIf="selectedSport!=='golf' && selectedSport!=='fitness'">{{ session.price / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }}</span>

            <div class="btn-availabilities-container" >
              <button (click)="onBookActivity(session,selectedSlot)" class="button-rounded btn-show-tarifs">
                <p>{{'select_activity.book' | translate }} </p>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="isLoggedIn && serviceInformationToShow.length == 0">
    <div class="text-center">
      <p>{{ 'center_availabilities.no_availabilities' | translate }}</p>
    </div>

  </div>
  <div *ngIf="!isLoggedIn && serviceInformationToShow.length == 0">
    <div class="text-center">
      <button class="button-rounded btn" (click)="redirectToLogin()">
        <p>{{ 'center_availabilities.connect_to_see_prices' | translate }}</p>
      </button>
    </div>
  </div>
</ng-template>
