export const environment = {
  production: false,
  useEmulators: true,
  organiserApiUrl: 'https://organiser.stage.anybuddyapp.com',
  bookingApiUrl: 'https://booking.stage.anybuddyapp.com',
  authorize: 'https://api-global.preprod.decathlon.net/connect/oauth/authorize',
  accessToken: 'https://api-global.preprod.decathlon.net/connect/oauth/token',
  clientId: '9b724c00-f044-4ff2-ab82-c21eddf22022',
  clientSecret:
    'WOdiDH4Nj5B1fvEDvYQw3oOokmfwO449P2YN0D0MbnSjm9pnQH5PnOG8s5eDuqT3JscVLZJLVVkOSqNP6lOxjNW50Fw2iIHG52HjDkA5pFeWVRE2YwC48hY0M2D7b2d3',
  stripeKey: 'pk_test_QXR8A8TdkNLve12z277YYGV9',
  host: 'https://webapp.stage.anybuddyapp.com',
  imageCDN: 'https://res.cloudinary.com/anybuddy/image/upload/',
  gtmId: 'GTM-PB6V6G8',
  firebase: {
    dynamicLinkDomain: 'j9r7w.app.goo.gl',
    apiKey: 'AIzaSyCaV02dWbuBC_GQERfPC2-DPClPC1m73Bw',
    authDomain: 'anybuddy-stage.firebaseapp.com',
    databaseURL: 'https://anybuddy-stage.firebaseio.com',
    projectId: 'anybuddy-stage',
    storageBucket: 'anybuddy-stage.appspot.com',
    messagingSenderId: '865440223539',
    appId: '1:865440223539:web:0f09d668cbf8ff8060ae46',
    measurementId: 'G-0LHRDXKCXV',
    appCheckKey: '6LcWQP0oAAAAABbPaHSloBcVR3dzSQsG1kZTNjaI'
  }
};
